import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IFaqStore } from 'interface/IFaq'
import { DELETE, GET, POST } from 'service'
import Constant from 'utils/constants'
import { showToast } from './notificationSlice'
import { AxiosError } from 'axios'

const constant = new Constant()
const initialState: IFaqStore = {
  isLoading: false,
  faqs: [],
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getAll = createAsyncThunk('faqs/getAll', async () => {
  const result = await GET(constant.API_URLS.GET_FAQS, {})
  return JSON.stringify(result)
})

export const addFaq = createAsyncThunk(
  'faqs/add',
  async (params: object, thunkAPI) => {
    try {
      const result = await POST(constant.API_URLS.ADD_FAQS, params, {})
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.SUCCESS,
          body: result?.data?.message,
          variant: constant.TOAST_VARIANT.SUCCESS,
        })
      )
      return JSON.stringify(result)
    } catch (err) {
      const error = err as AxiosError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(null)
    }
  }
)

export const deleteFaq = createAsyncThunk(
  'faqs/deleteFaq',
  async (id: number, thunkAPI) => {
    try {
      const result = await DELETE(
        constant.API_URLS.DELETE_FAQS.replace('$id', id.toString()),
        {}
      )
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.SUCCESS,
          body: result?.data?.message,
          variant: constant.TOAST_VARIANT.SUCCESS,
        })
      )
      thunkAPI.dispatch(getAll())
      return JSON.stringify(result)
    } catch (err) {
      const error = err as AxiosError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(null)
    }
  }
)

export const faqSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {
    setFaqs: (state, { payload }) => {
      state.faqs = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAll.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAll.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getAll.fulfilled, (state, { payload }) => {
        state.isLoading = false
        const parsedPayload = JSON.parse(payload)
        state.faqs = parsedPayload.data
      })
      .addCase(addFaq.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addFaq.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(addFaq.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(deleteFaq.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteFaq.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(deleteFaq.fulfilled, (state) => {
        state.isLoading = false
      })
  },
})

export const { setFaqs } = faqSlice.actions

export default faqSlice.reducer
