import React from 'react'
import { Link } from 'react-router-dom'
import Constant from 'utils/constants'

const ProjectTypes = () => {
  const constant = new Constant()
  const projects = [
    {
      id: 1,
      heading: 'Commercial',
      headingLink: `/portfolio/${constant.CONSTRUCTION_TYPE.COMMERCIAL}`,
      text: 'Transforming spaces for success. Expert planning, efficient execution, and exceptional quality with material. Elevate your commercial vision with our reliable partnership.',
      image: 'assets/images/gallery/17.jpg',
      iconImg: 'assets/images/icons/service-1.svg',
    },
    {
      id: 2,
      heading: 'Residential',
      headingLink: `/portfolio/${constant.CONSTRUCTION_TYPE.RESIDENTIAL}`,
      text: 'Elevating homes with expertise and care. Custom designs, timely delivery, and superior craftsmanship. Trust us to bring your residential vision to life.',
      image: 'assets/images/gallery/18.jpg',
      iconImg: 'assets/images/icons/service-51.png',
    },
    {
      id: 3,
      heading: 'In House',
      headingLink: `/portfolio/${constant.CONSTRUCTION_TYPE.INHOUSE}`,
      text: 'Elevating hospitality experiences with expertise. Customized designs, seamless execution, and exceptional service. Trust us to create inviting spaces that leave a lasting impression',
      image: 'assets/images/gallery/19.jpg',
      iconImg: 'assets/images/icons/service-3.svg',
    },
    // {
    //   id: 4,
    //   heading: 'Civil Work',
    //   headingLink: `/portfolio/${constant.CONSTRUCTION_TYPE.CIVIL_WORK}`,
    //   text: 'Elevating hospitality experiences with expertise. Customized designs, seamless execution, and exceptional service. Trust us to create inviting spaces that leave a lasting impression',
    //   image: 'assets/images/gallery/19.jpg',
    //   iconImg: 'assets/images/icons/service-3.svg',
    // },
    // {
    //   id: 5,
    //   heading: 'Township Land Design',
    //   headingLink: `/portfolio/${constant.CONSTRUCTION_TYPE.TOWNSHIP_LAND_DESIGN}`,
    //   text: 'Elevating hospitality experiences with expertise. Customized designs, seamless execution, and exceptional service. Trust us to create inviting spaces that leave a lasting impression',
    //   image: 'assets/images/gallery/19.jpg',
    //   iconImg: 'assets/images/icons/service-3.svg',
    // },
    // {
    //   id: 6,
    //   heading: 'Land Survey',
    //   headingLink: `/portfolio/${constant.CONSTRUCTION_TYPE.LAND_SURVEY}`,
    //   text: 'Elevating hospitality experiences with expertise. Customized designs, seamless execution, and exceptional service. Trust us to create inviting spaces that leave a lasting impression',
    //   image: 'assets/images/gallery/19.jpg',
    //   iconImg: 'assets/images/icons/service-3.svg',
    // },
  ]

  const renderProjectTypes = () =>
    projects?.map(({ id, heading, headingLink, text, image, iconImg }) => (
      <div
        key={`pt_${id}`}
        className="project-block_four col-lg-4 col-md-6 col-sm-12"
      >
        <div className="project-block_four-inner">
          <div className="project-block_four-icon">
            <img src={iconImg} alt="" />
          </div>
          <h3 className="project-block_four-heading">
            <Link to={headingLink}>{heading}</Link>
          </h3>
          <div className="project-block_four-image">
            <Link to={headingLink}>
              <img src={image} alt="" />
            </Link>
          </div>
          <div className="project-block_four-text">{text}</div>
          <Link
            className="project-block_four-plus fa-solid fa-plus fa-fw"
            to={headingLink}
          ></Link>
        </div>
      </div>
    ))
  return (
    <section className="project-four">
      <div
        className="project-four_image-layer"
        style={{
          backgroundImage:
            "url('assets/images/background/project-four_bg.jpg')",
        }}
      ></div>
      <div className="auto-container">
        <div className="sec-title d-flex justify-content-between align-items-center flex-wrap">
          <div className="left-box">
            <div className="sec-title_title">house in a conservation area</div>
            <h2 className="sec-title_heading">
              Project types we <br /> work on
            </h2>
          </div>
          <div className="sec-title_text">
            Crafting dreams with precision and passion. Tailored solutions,
            on-time delivery, and client-centric communication. Trust our
            expertise for quality results and lasting partnerships.
          </div>
        </div>
        <div className="inner-container">
          <div className="row clearfix">{renderProjectTypes()}</div>
        </div>
      </div>
    </section>
  )
}

export default ProjectTypes
