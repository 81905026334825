import React from 'react'

const History = () => {
  const companyHistory = [
    {
      year: 1999,
      heading: 'ROYAL GOLD MEDAL',
      text: 'Lorem ipsum dolor sit amet, consectetur adipi scing elit. Ut elit tellus, luctus nec ullamcorpe. Lorem ipsum dolor sit amet, consectetur adipi.',
    },
    {
      year: 2000,
      heading: 'ROYAL GOLD MEDAL',
      text: 'Lorem ipsum dolor sit amet, consectetur adipi scing elit. Ut elit tellus, luctus nec ullamcorpe. Lorem ipsum dolor sit amet, consectetur adipi.',
    },
    {
      year: 2005,
      heading: 'ROYAL GOLD MEDAL',
      text: 'Lorem ipsum dolor sit amet, consectetur adipi scing elit. Ut elit tellus, luctus nec ullamcorpe. Lorem ipsum dolor sit amet, consectetur adipi.',
    },
    {
      year: 2010,
      heading: 'ROYAL GOLD MEDAL',
      text: 'Lorem ipsum dolor sit amet, consectetur adipi scing elit. Ut elit tellus, luctus nec ullamcorpe. Lorem ipsum dolor sit amet, consectetur adipi.',
    },
    {
      year: 2015,
    },
    {
      year: 2020,
    },
    {
      year: 2023,
    },
  ]

  const renderHistory = () => {
    return companyHistory?.map((r) => (
      <div className="swiper-slide">
        <div
          className="history-image"
          style={{
            backgroundImage: "url('assets/images/background/history.jpg')",
          }}
        >
          <div className="history-one_content">
            <h4>{r?.heading}</h4>
            <p>{r?.text}</p>
          </div>
        </div>
      </div>
    ))
  }

  const renderYears = () => {
    return companyHistory?.map((r) => (
      <div className="swiper-slide">
        <div className="slider-one_year">{r?.year}</div>
      </div>
    ))
  }
  return (
    <section className="history-one">
      <div className="auto-container">
        <div className="sec-title centered">
          <div className="sec-title_icon">
            <img src="assets/images/icons/logo-icon.png" alt="" />
          </div>
          <div className="sec-title_title">AWARD WINNING ARCH STUDIO</div>
          <h2 className="sec-title_heading">Company History</h2>
        </div>
      </div>
      <div className="swiper history_content swiper-container">
        <div className="swiper history_years">
          <div className="swiper-wrapper ">{renderYears()}</div>
          <div className="years-one_arrow">
            <div className="years-one_button-prev">
              <img src="assets/images/icons/client-one_prev-arrow.png" alt="" />
            </div>
            <div className="years-one_button-next">
              <img src="assets/images/icons/client-one_next-arrow.png" alt="" />
            </div>
          </div>
        </div>

        <div className="swiper-wrapper">{renderHistory()}</div>
      </div>
    </section>
  )
}

export default History
