import { IClient } from 'interface/IClient'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { getAll } from 'store/reducers/clientSlice'

const Clients = () => {
  const dispatch = useDispatch<AppDispatch>()
  const clients = useSelector<RootState>(
    (state) => state?.clients?.clients
  ) as Array<IClient>
  useEffect(() => {
    dispatch(getAll())
  }, [])
  const renderClients = () => {
    return clients?.map((client) => {
      return (
        <div className="swiper-slide">
          <div className="client-image">
            <img src={client.logo} alt="" />
          </div>
        </div>
      )
    })
  }

  return (
    <div className="clients-one">
      <div className="inner-container">
        <div className="clients-one_slider swiper-container">
          <div className="swiper-wrapper">{renderClients()}</div>
          <div className="clients-one_arrow">
            <div className="clients-one_slider_button-prev">
              <img src="assets/images/icons/client-one_prev-arrow.png" alt="" />
            </div>
            <div className="clients-one_slider_button-next">
              <img src="assets/images/icons/client-one_next-arrow.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Clients
