import { ISessionDtls } from 'admin/interfaces/ISession'
import moment from 'moment'
import secureLocalStorage from 'react-secure-storage'
export const setSessionDtls = (data: ISessionDtls) =>
  secureLocalStorage.setItem('session', data)
export const getSessionDtls = () =>
  (secureLocalStorage.getItem('session') as ISessionDtls) || {}
export const logout = () => secureLocalStorage.setItem('session', '')

export const isAuth = () => (getSessionDtls()?.token ? true : false)
export const getLoggedInEmail = () => getSessionDtls()?.emailId

export const isVideo = (url: string) => {
  const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'webm']
  const extension = url?.split('.')?.pop()?.toLowerCase()
  return extension ? videoExtensions.includes(extension) : false
}

export const convertBlobToBase64 = (blob: any) => {
  if (!blob?.data) {
    return null
  }
  const base64Str = btoa(String.fromCharCode(...new Uint8Array(blob?.data)))
  return base64Str
}

export const getDateTime = (datetime: string) => {
  return moment(datetime).format('DD MMM, YYYY')
}

export const BASE64_IMAGE_TYPE = 'data:image/jpeg;charset=utf-8;base64,'
