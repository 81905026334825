import React from 'react'

const Slider = () => {
  const slides = [
    {
      image: 'assets/images/main-slider/1.jpg',
      heading: 'Brick, Wood, Steel, Concrete.',
      text: 'Truly unique designs that are specially curated to perfection',
    },
    {
      image: 'assets/images/main-slider/2.jpg',
      heading: 'Brick, Wood, Steel, Concrete.',
      text: 'Truly unique designs that are specially curated to perfection',
    },
    {
      image: 'assets/images/main-slider/3.jpg',
      heading: 'Brick, Wood, Steel, Concrete.',
      text: 'Truly unique designs that are specially curated to perfection',
    },
    {
      image: 'assets/images/main-slider/4.jpg',
      heading: 'Brick, Wood, Steel, Concrete.',
      text: 'Truly unique designs that are specially curated to perfection',
    },
    {
      image: 'assets/images/main-slider/5.jpg',
      heading: 'Brick, Wood, Steel, Concrete.',
      text: 'Truly unique designs that are specially curated to perfection',
    },
  ]
  const renderSlide = () => {
    return slides?.map((slide, idx) => {
      return (
        <div className="swiper-slide" key={`slider_${idx}`}>
          <div
            className="slider-three_image-layer"
            style={{
              backgroundImage: `url(${slide.image})`,
            }}
          ></div>
          <div className="auto-container">
            <div className="slider-three_content">
              <h1 className="slider-three_heading">{slide?.heading}</h1>
              <div className="slider-three_text">{slide?.text}</div>
              <div className="slider-three_button">
                <a href="#" className="theme-btn btn-style-two">
                  <span className="btn-wrap">
                    <span className="text-one">Discover Projects</span>
                    <span className="text-two">Discover Projects</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }
  return (
    <section className="slider-three">
      <div className="main-slider_three swiper-container">
        <div className="swiper-wrapper">{renderSlide()}</div>
        <div className="slider-three-arrow">
          <div className="slider-three_prev">
            <img
              src="assets/images/main-slider/slider-two-prev_arrow.png"
              alt=""
            />
          </div>
          <div className="slider-three_next">
            <img
              src="assets/images/main-slider/slider-two-next_arrow.png"
              alt=""
            />
          </div>
        </div>
        <div className="slider-three_pagination"></div>
      </div>
    </section>
  )
}

export default Slider
