import Breadcrumb from 'components/breadcrumb'
import Projects from 'components/projects'
import { IProject } from 'interface/IProjects'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { getProjectsByService } from 'store/reducers/projectSlice'

interface IPortfolios {
  serviceName: string
}

const Portfolios: React.FC<IPortfolios> = ({ serviceName }) => {
  const dispatch = useDispatch<AppDispatch>()
  const projects: Array<IProject> = useSelector<RootState>(
    (state) => state?.projects?.projects
  ) as Array<IProject>
  useEffect(() => {
    dispatch(getProjectsByService(serviceName))
  }, [serviceName])

  return (
    <>
      <Breadcrumb item={serviceName} />
      <Projects projects={projects} />
    </>
  )
}

export default Portfolios
