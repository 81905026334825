/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import Constant from 'utils/constants'

const constant = new Constant()
const axiosClient = axios.create({
  baseURL: constant.API_HOST_URL,
})

axiosClient.interceptors.request.use(
  async (config) => {
    if (!config?.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json'
      config.headers['content-disposition'] = 'attachment;'
    }
    config.headers['Access-Control-Allow-Headers'] =
      'Origin, Content-Type, X-Auth-Token'
    config.headers['Access-Control-Allow-Origin'] = '*'

    //config.headers["Authorization"] = "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImFwcElkLWYxNDMyZmZmLWQ1ODgtNDg3Ny04NWYwLTI1ZjE0YTIyOWZhMy0yMDIzLTA2LTI3VDA1OjU0OjMxLjQzNTQyMzU0MiIsInZlciI6NH0.eyJpc3MiOiJodHRwczovL2V1LWRlLmFwcGlkLmNsb3VkLmlibS5jb20vb2F1dGgvdjQvZjE0MzJmZmYtZDU4OC00ODc3LTg1ZjAtMjVmMTRhMjI5ZmEzIiwiZXhwIjoxNjkwMTk3ODYwLCJhdWQiOlsiNDNiZGY0NjItY2QzMC00MTAwLWJlYjctNGQ5OWRjNWRhZDE3Il0sInN1YiI6Ijk4NTA4NGM2LWFiMDQtNDI1My05ZDQ5LWM4MjVjZjk2OWQ4YSIsImFtciI6WyJpYm1pZCJdLCJpYXQiOjE2OTAxOTQyNjAsInRlbmFudCI6ImYxNDMyZmZmLWQ1ODgtNDg3Ny04NWYwLTI1ZjE0YTIyOWZhMyIsInNjb3BlIjoib3BlbmlkIGFwcGlkX2RlZmF1bHQgYXBwaWRfcmVhZHVzZXJhdHRyIGFwcGlkX3JlYWRwcm9maWxlIGFwcGlkX3dyaXRldXNlcmF0dHIgYXBwaWRfYXV0aGVudGljYXRlZCJ9.QrJw7nVm1OvWIfFxcbRmU07J7c9Myd0RZnV1Ev7A2u_b4ZPCICmXiTfPS56MS6r0If5bbAOTSnwFsQ5Yo-N2NIED4iG27hMlD_z2xf88X-W2oirb4qybSOUNbG2-ocYkKqTICPzlm3D8Aqsag8Aff2rOIsQdnJ2KhpLZatoptZdr0GJs5J8x6q2a1fsDyQycfs6Y76j7qhHjlhcWQLHFI7sIwmDJ3gpqBKxXKro1dDK21FK3emy75Z58URdDGmCbReAIT4qljp_CB3fb1el8vEn_wkaH6ZftZFgvNNzTusPDuJcfUCsulzmH38efTRZgBoT5F3iaZfreDgGq2EDwpw";
    return config
  },
  (error: any) => Promise.reject(error)
)

axiosClient.interceptors.response.use(
  function (response: AxiosResponse<any, any>) {
    response.headers['Content-Type'] = 'application/zip'
    response.headers['content-disposition'] = 'attachment; filename=rfp.zip '
    return responseHandler(response)
  },
  function (error: {
    response: { status: number }
    message: string | string[]
  }) {
    if (error.response) {
      if (401 === error.response.status) {
      }
    } else if (error.message.includes('timeout')) {
      return Promise.reject(null)
    } else {
    }
    return Promise.reject(error)
  }
)

export function GET(URL: any, config: AxiosRequestConfig<any> | undefined) {
  return axiosClient.get(`${URL}`, config).then((response: any) => response)
}

export function POST(
  URL: any,
  payload: any,
  config: AxiosRequestConfig<any> | undefined
) {
  return axiosClient
    .post(`${URL}`, payload, config)
    .then((response: any) => response)
}

export function PATCH(
  URL: any,
  payload: any,
  config: AxiosRequestConfig<any> | undefined
) {
  return axiosClient
    .patch(`/${URL}`, payload, config)
    .then((response: any) => response)
}

export function PUT(
  URL: any,
  payload: any,
  config: AxiosRequestConfig<any> | undefined
) {
  return axiosClient
    .put(`/${URL}`, payload, config)
    .then((response: any) => response)
}

export function DELETE(URL: any, params: any) {
  return axiosClient
    .delete(`/${URL}`, { data: params })
    .then((response: any) => response)
}

const responseHandler = (res: AxiosResponse<any, any>) => {
  if (constant.HTTP_STATUS.OK !== res.status) {
    throw new Error('Network error')
  }
  if (res.status >= 400) {
    return Promise.reject(res.data)
  }

  return res
}

export default {
  GET,
  PUT,
  POST,
  DELETE,
}
