import { INotification } from 'admin/interfaces/INotification'
import React from 'react'
import { ToastContainer } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Toast from 'react-bootstrap/Toast'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { closeToast } from 'store/reducers/notificationSlice'

const Notification: React.FC = () => {
  const { title, body, variant } = useSelector<RootState>(
    (state) => state?.notification
  ) as INotification
  const dispatch = useDispatch<AppDispatch>()
  if (!title && !body && !variant) {
    return null
  } else {
    window.scrollTo(0, 0)
  }

  const handleClose = () => {
    dispatch(closeToast())
  }
  return (
    <Row>
      <Col xs={6}>
        <ToastContainer className="p-3" position="top-end">
          <Toast
            onClose={handleClose}
            show={title && body && variant ? true : false}
            delay={3000}
            autohide
            bg={variant}
          >
            <Toast.Header>
              <strong className="me-auto">{title}</strong>
            </Toast.Header>
            <Toast.Body>{body}</Toast.Body>
          </Toast>
        </ToastContainer>
      </Col>
    </Row>
  )
}

export default Notification
