import Breadcrumb from 'components/breadcrumb'
import Services from 'components/services'
import React from 'react'

const ServicePage: React.FC = () => {
  return (
    <>
      <Breadcrumb item="Company Services" />
      <Services />
    </>
  )
}

export default ServicePage
