import React from 'react'
import Constant from 'utils/constants'

const StayConnected = () => {
  const constant = new Constant()
  return (
    <section className="cta-three">
      <div
        className="cta-three_image-layer"
        style={{
          backgroundImage: "url('assets/images/background/cta-three_bg.jpg')",
        }}
      ></div>
      <div className="auto-container">
        <div className="cta-three_circle">
          <img src="assets/images/main-slider/circle.png" alt="" />
        </div>
        <h1 className="cta-three_heading">Have a Project?</h1>
        <div className="cta-three_text">
          stay connected with our team and never miss a design update
        </div>
        <div className="cta-three_button">
          <a
            href={constant.WHATS_APP_URL}
            target="_blank"
            className="theme-btn btn-style-one"
          >
            <span className="btn-wrap">
              <span className="text-one">Let’s Start Talk</span>
              <span className="text-two">Let’s Start Talk</span>
            </span>
          </a>
        </div>
      </div>
    </section>
  )
}

export default StayConnected
