import IFooter from 'interface/IFooter'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from 'store'
import Constant from 'utils/constants'

const Footer: React.FC<IFooter> = ({ backToTop }) => {
  const constant = new Constant()
  const services = useSelector<RootState>(
    (state) => state.services?.serviceNames
  ) as Array<{ heading: string }>

  const renderQuickLinks = () => {
    return services?.map((service) => (
      <li>
        <Link to={`/portfolio/${service?.heading}`}>{service?.heading}</Link>
      </li>
    ))
  }
  return (
    <>
      <footer className="footer-style-two">
        <div
          className="footer-two_bg-image"
          style={{
            backgroundImage: "url('assets/images/background/footer-bg-2.jpg')",
          }}
        ></div>
        <div className="auto-container">
          <div className="widgets-section">
            <div className="row clearfix">
              <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                <div className="footer-widget info-widget">
                  <h5>Information</h5>
                  <p>
                    Precision meets proficiency in our survey and construction
                    business. Expertise, innovation, and commitment define our
                    approach. Trust us to transform visions into reality with
                    meticulous attention to detail and unwavering dedication.
                  </p>
                </div>
              </div>

              <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                <div className="footer-widget contact-widget">
                  <h5>quick links</h5>
                  <ul className="footer-list">{renderQuickLinks()}</ul>
                </div>
              </div>

              <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                <div className="footer-widget newsletter-widget">
                  <h5>Newsletter</h5>
                  <p>subscribe and get recent news and offers.</p>
                  <div className="subscribe-box style-three">
                    <form method="post" action="#">
                      <div className="form-group">
                        <input
                          type="email"
                          name="search-field"
                          value=""
                          placeholder="Enter Your Email"
                          required
                        />
                        <button type="submit">Subscribe</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="auto-container">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div className="footer-bottom_logo">
                <a href="#">
                  <img src="assets/images/SDD-LOGO-low.png" alt="" title="" />
                </a>
              </div>
              <div className="copyright">
                Copyright &copy; 2024. All Rights Reserved.
              </div>
              <div className="footer_socials">
                <a href={constant.SOCIAL_NETWORKS_URL.FB} target="_blank">
                  <img src="assets/images/resource/fb.png" alt="" />
                </a>
                <a href={constant.SOCIAL_NETWORKS_URL.TWITTER} target="_blank">
                  <img src="assets/images/resource/x.png" alt="" />
                </a>
                <a href={constant.SOCIAL_NETWORKS_URL.YOUTUBE} target="_blank">
                  <img src="assets/images/resource/youtube.png" alt="" />
                </a>
                <a href={constant.SOCIAL_NETWORKS_URL.INSTA} target="_blank">
                  <img src="assets/images/resource/insta.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="progress-wrap" ref={backToTop}>
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
    </>
  )
}

export default Footer
