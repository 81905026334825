import { createSlice } from '@reduxjs/toolkit'
import { IAdminStore } from 'interface/IAdmin'

const initialState: IAdminStore = {
  isLoading: false,
  sidebarShow: true,
  theme: 'light',
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    changeState: (state, { payload }) => {
      const { sidebarShow, theme } = payload as IAdminStore
      state.sidebarShow = sidebarShow
      state.theme = theme
    },
  },
})

export const { changeState } = adminSlice.actions

export default adminSlice.reducer
