import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { DELETE, GET, POST, PUT } from 'service'
import Constant from 'utils/constants'
import { showToast } from './notificationSlice'
import { AxiosError } from 'axios'
import { IEventStore } from 'interface/IEvent'

const constant = new Constant()
const initialState: IEventStore = {
  isLoading: false,
  events: [],
  eventDtls: null,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getAll = createAsyncThunk('events/getAll', async () => {
  const result = await GET(constant.API_URLS.GET_EVENTS, {})
  return JSON.stringify(result)
})

export const addEvent = createAsyncThunk(
  'events/add',
  async (params: object, thunkAPI) => {
    try {
      const result = await POST(constant.API_URLS.ADD_EVENTS, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.SUCCESS,
          body: result?.data?.message,
          variant: constant.TOAST_VARIANT.SUCCESS,
        })
      )
      return JSON.stringify(result)
    } catch (err) {
      const error = err as AxiosError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(null)
    }
  }
)

export const updateEvent = createAsyncThunk(
  'events/update',
  async (params: object, thunkAPI) => {
    try {
      const result = await PUT(constant.API_URLS.UPDATE_EVENTS, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.SUCCESS,
          body: result?.data?.message,
          variant: constant.TOAST_VARIANT.SUCCESS,
        })
      )
      return JSON.stringify(result)
    } catch (err) {
      const error = err as AxiosError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(null)
    }
  }
)

export const getEventById = createAsyncThunk(
  'events/getEventById',
  async (id: string) => {
    const result = await GET(
      constant.API_URLS.GET_EVENT_BY_ID.replace('$id', id),
      {}
    )
    return JSON.stringify(result)
  }
)

export const deleteEvent = createAsyncThunk(
  'events/deleteEvent',
  async (id: number, thunkAPI) => {
    try {
      const result = await DELETE(
        constant.API_URLS.DELETE_EVENTS.replace('$id', id.toString()),
        {}
      )
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.SUCCESS,
          body: result?.data?.message,
          variant: constant.TOAST_VARIANT.SUCCESS,
        })
      )
      thunkAPI.dispatch(getAll())
      return JSON.stringify(result)
    } catch (err) {
      const error = err as AxiosError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(null)
    }
  }
)

export const faqSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAll.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAll.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getAll.fulfilled, (state, { payload }) => {
        state.isLoading = false
        const parsedPayload = JSON.parse(payload)
        state.events = parsedPayload.data
      })
      .addCase(addEvent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addEvent.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(addEvent.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateEvent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateEvent.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateEvent.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(deleteEvent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteEvent.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(deleteEvent.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(getEventById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getEventById.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getEventById.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.eventDtls = JSON.parse(payload)?.data
      })
  },
})

export default faqSlice.reducer
