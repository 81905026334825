import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IServiceStore } from 'interface/IService'
import { DELETE, GET, POST, PUT } from 'service'
import Constant from 'utils/constants'
import { showToast } from './notificationSlice'
import { AxiosError } from 'axios'

const constant = new Constant()
const initialState: IServiceStore = {
  isLoading: false,
  services: [],
  serviceNames: [],
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getAll = createAsyncThunk('services/getAll', async () => {
  const result = await GET(constant.API_URLS.GET_SERVICES, {})
  return JSON.stringify(result)
})

export const addService = createAsyncThunk(
  'services/add',
  async (params: object, thunkAPI) => {
    try {
      const result = await POST(constant.API_URLS.ADD_SERVICES, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.SUCCESS,
          body: result?.data?.message,
          variant: constant.TOAST_VARIANT.SUCCESS,
        })
      )
      return JSON.stringify(result)
    } catch (err) {
      const error = err as AxiosError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(null)
    }
  }
)

export const updateService = createAsyncThunk(
  'services/update',
  async (params: object, thunkAPI) => {
    try {
      const result = await PUT(constant.API_URLS.UPDATE_SERVICES, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.SUCCESS,
          body: result?.data?.message,
          variant: constant.TOAST_VARIANT.SUCCESS,
        })
      )
      return JSON.stringify(result)
    } catch (err) {
      const error = err as AxiosError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(null)
    }
  }
)

export const deleteService = createAsyncThunk(
  'services/deleteService',
  async (id: number, thunkAPI) => {
    try {
      const result = await DELETE(
        constant.API_URLS.DELETE_SERVICES.replace('$id', id.toString()),
        {}
      )
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.SUCCESS,
          body: result?.data?.message,
          variant: constant.TOAST_VARIANT.SUCCESS,
        })
      )
      thunkAPI.dispatch(getAll())
      return JSON.stringify(result)
    } catch (err) {
      const error = err as AxiosError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(null)
    }
  }
)

export const ServicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setServices: (state, { payload }) => {
      state.serviceNames = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAll.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAll.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getAll.fulfilled, (state, { payload }) => {
        state.isLoading = false
        const parsedPayload = JSON.parse(payload)
        state.services = parsedPayload.data
      })
      .addCase(addService.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addService.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(addService.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateService.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateService.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateService.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(deleteService.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteService.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(deleteService.fulfilled, (state) => {
        state.isLoading = false
      })
  },
})

export const { setServices } = ServicesSlice.actions

export default ServicesSlice.reducer
