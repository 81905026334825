import React from 'react'
import {
  CAvatar,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { cilBell, cilAccountLogout } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { logout } from 'utils/utils'
import { Link } from 'react-router-dom'

const HeaderDropdown = () => {
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle className="py-0 pe-0" caret={false}>
        <CAvatar src={'assets/admin/images/avatars/9.jpg'} size="md" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0">
        <CDropdownHeader className="bg-body-secondary fw-semibold mb-2">
          Account
        </CDropdownHeader>
        <CDropdownItem as={Link} to="/admin/change-password">
          <CIcon icon={cilBell} className="me-2" />
          Change Passowrd
        </CDropdownItem>
        <CDropdownItem as={Link} to="/login" onClick={() => logout()}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default HeaderDropdown
