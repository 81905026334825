import IProjects from 'interface/IProjects'
import React from 'react'
import { Link } from 'react-router-dom'
import { getDateTime } from 'utils/utils'

const Projects: React.FC<IProjects> = ({ projects }) => {
  const renderProjects = () =>
    projects?.map(({ id, client, image, heading, completedDate, service }) => {
      const headingLink = `/portfolio/${service?.heading}/${id}/${heading}`
      return (
        <div
          key={`p_${id}`}
          className="project-block_three col-xl-3 col-lg-6 col-md-6 col-sm-6"
        >
          <div className="project-block_three-inner">
            <div className="project-block_three-image">
              <img src={image} alt="" />
              <div className="project-block_three-overlay">
                <div className="project-block_three-content">
                  <div className="project-block_three-title">{client}</div>
                  <h5 className="project-block_three-heading">
                    <Link to={headingLink}>{heading}</Link>
                  </h5>
                  <div className="project-block_three-date">
                    {getDateTime(completedDate)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })
  return (
    <div className="upper-box mt-2">
      <div className="row clearfix">{renderProjects()}</div>
    </div>
  )
}

export default Projects
