import { IEvent } from 'interface/IEvent'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { getAll } from 'store/reducers/eventSlice'

const Events = () => {
  const dispatch = useDispatch<AppDispatch>()
  const events = useSelector<RootState>(
    (state) => state?.events?.events
  ) as Array<IEvent>
  useEffect(() => {
    dispatch(getAll())
  }, [])
  const renderEvents = () => {
    return events?.map((event) => {
      return (
        <div
          key={`event_${event?.id}`}
          className="news-block_one col-lg-4 col-md-6 col-sm-12"
        >
          <div
            className="news-block_one-inner wow fadeInRight"
            data-wow-delay="300ms"
            data-wow-duration="1500ms"
          >
            <div className="news-block_one-image">
              <Link to={`/event-details/${event?.id}`}>
                <img src={event?.image} alt="" />
              </Link>
            </div>
            <div className="news-block_one-content">
              <div className="news-block_one-date">
                {moment(event?.datetime, 'DD-MM-YYYY').format('DD')}{' '}
                <span>
                  {moment(event?.datetime, 'DD-MM-YYYY').format('MMM')}
                </span>
              </div>
              <div className="news-block_one-title">{event?.title}</div>
              <h5 className="news-block_one-heading">
                <Link to={`/event-details/${event?.id}`}>{event?.heading}</Link>
              </h5>
              <Link
                to={`/event-details/${event?.id}`}
                className="news-block_one-more"
              >
                Read More
              </Link>
            </div>
          </div>
        </div>
      )
    })
  }
  return (
    <section className="news-one">
      <div className="auto-container">
        <div className="row clearfix">{renderEvents()}</div>
      </div>
    </section>
  )
}

export default Events
