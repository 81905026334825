import IBreadcrumb from 'interface/IBreadcrumb'
import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumb: React.FC<IBreadcrumb> = ({
  parentItem = 'Home',
  parentLink = '/',
  item,
  bgImage,
}) => {
  const backImg = bgImage
    ? bgImage
    : '/assets/images/background/page-title_bg.jpg'
  return (
    <section
      className="page-title"
      style={{
        backgroundImage: `url(${backImg})`,
      }}
    >
      <div className="auto-container">
        <h2>{item}</h2>
        <ul className="bread-crumb clearfix">
          <li>
            <Link to={parentLink}>{parentItem}</Link>
          </li>
          <li>{item}</li>
        </ul>
      </div>
    </section>
  )
}

export default Breadcrumb
