import AboutUs from 'components/aboutus'
import Breadcrumb from 'components/breadcrumb'
import History from 'components/history'
import Services from 'components/services'
import StayConnected from 'components/stay-connected'
import importScript from 'hooks/import-script'
import React from 'react'

const AboutUsPage = () => {
  importScript()
  return (
    <>
      <Breadcrumb item="About Our Company" />
      <AboutUs />
      <History />
      <Services />
      {/* <ExpertTeam /> */}
      {/* <BuildingProgress /> */}
      <StayConnected />
    </>
  )
}

export default AboutUsPage
