import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IProjectStoreInitialState } from 'interface/IProjects'
import { DELETE, GET, POST, PUT } from 'service'
import Constant from 'utils/constants'
import { showToast } from './notificationSlice'
import { AxiosError } from 'axios'

const constant = new Constant()
const initialState: IProjectStoreInitialState = {
  isLoading: false,
  projectDtls: null,
  projects: [],
  TopProjects: [],
  allProjects: [],
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getProjectsByService = createAsyncThunk(
  'projects/getProjectsByService',
  async (serviceName: string) => {
    const result = await GET(
      constant.API_URLS.GET_PROJECTS_BY_SERVICE_NAME.replace(
        '$serviceName',
        serviceName
      ),
      {}
    )
    return JSON.stringify(result)
  }
)
export const getAll = createAsyncThunk('projects/getAll', async () => {
  const result = await GET(constant.API_URLS.GET_PROJECT, {})
  return JSON.stringify(result)
})
export const getProjectById = createAsyncThunk(
  'projects/getProjectById',
  async (id: string) => {
    const result = await GET(
      constant.API_URLS.GET_PROJECT_BY_ID.replace('$id', id),
      {}
    )
    return JSON.stringify(result)
  }
)

export const getTop8Projects = createAsyncThunk(
  'projects/getTop8Projects',
  async () => {
    const result = await GET(constant.API_URLS.GET_TOP_8_PROJECTS, {})
    return JSON.stringify(result)
  }
)

export const addProject = createAsyncThunk(
  'projects/add',
  async (params: object, thunkAPI) => {
    try {
      const result = await POST(constant.API_URLS.ADD_PROJECT, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.SUCCESS,
          body: result?.data?.message,
          variant: constant.TOAST_VARIANT.SUCCESS,
        })
      )
      return JSON.stringify(result)
    } catch (err) {
      const error = err as AxiosError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(null)
    }
  }
)
export const updateProject = createAsyncThunk(
  'projects/update',
  async (params: object, thunkAPI) => {
    try {
      const result = await PUT(constant.API_URLS.UPDATE_PROJECT, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.SUCCESS,
          body: result?.data?.message,
          variant: constant.TOAST_VARIANT.SUCCESS,
        })
      )
      return JSON.stringify(result)
    } catch (err) {
      const error = err as AxiosError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(null)
    }
  }
)

export const deleteProject = createAsyncThunk(
  'projects/delete',
  async (id: number, thunkAPI) => {
    try {
      const result = await DELETE(
        constant.API_URLS.DELETE_PROJECT.replace('$id', id.toString()),
        {}
      )
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.SUCCESS,
          body: result?.data?.message,
          variant: constant.TOAST_VARIANT.SUCCESS,
        })
      )
      thunkAPI.dispatch(getAll())
      return JSON.stringify(result)
    } catch (err) {
      const error = err as AxiosError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(null)
    }
  }
)

export const ProjectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.projectDtls = null
      state.projects = []
      state.allProjects = []
    },
    setTopProjects: (state, { payload }) => {
      state.TopProjects = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProjectsByService.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getProjectsByService.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getProjectsByService.fulfilled, (state, { payload }) => {
        state.isLoading = false
        const parsedPayload = JSON.parse(payload)
        state.projects = parsedPayload.data
      })
      .addCase(getTop8Projects.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTop8Projects.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getTop8Projects.fulfilled, (state, { payload }) => {
        state.isLoading = false
        const parsedPayload = JSON.parse(payload)
        state.TopProjects = parsedPayload.data
      })
      .addCase(getAll.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAll.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getAll.fulfilled, (state, { payload }) => {
        state.isLoading = false
        const parsedPayload = JSON.parse(payload)
        state.allProjects = parsedPayload.data
      })
      .addCase(addProject.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addProject.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(addProject.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateProject.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateProject.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateProject.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(deleteProject.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteProject.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(deleteProject.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(getProjectById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getProjectById.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getProjectById.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.projectDtls = JSON.parse(payload)?.data
      })
  },
})

export const { reset: projectStoreReset, setTopProjects } = ProjectSlice.actions

export default ProjectSlice.reducer
