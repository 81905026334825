import Breadcrumb from 'components/breadcrumb'
import TeamDetails from 'components/team-details'
import React from 'react'

const TeamDetailsPage = () => {
  return (
    <>
      <Breadcrumb item="Team Detail" />
      <TeamDetails />
    </>
  )
}

export default TeamDetailsPage
