import { IEvent } from 'interface/IEvent'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { getEventById } from 'store/reducers/eventSlice'

const EventDetails = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { eventId } = useParams()
  const eventDtls = useSelector<RootState>(
    (state) => state?.events?.eventDtls
  ) as IEvent
  useEffect(() => {
    if (eventId) {
      dispatch(getEventById(eventId))
    }
  }, [])
  return (
    <>
      <div className="sidebar-page-container">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="content-side col-lg-9 col-md-12 col-sm-12">
              <div className="news-detail">
                <div className="inner-box">
                  <div className="lower-content">
                    <ul className="post-meta">
                      <li>
                        <span className="icon fa-regular fa-calendar fa-fw"></span>
                        {moment(eventDtls?.datetime, 'DD-MM-YYYY').format(
                          'DD MMM, YYYY'
                        )}
                      </li>
                      <li>
                        <span className="icon fa-solid fa-location fa-fw"></span>
                        {eventDtls?.location}
                      </li>
                    </ul>
                    <h3>{eventDtls?.title}</h3>

                    <div className="two-column">
                      <div className="row clearfix">
                        <div className="column col-lg-6 col-md-6 col-sm-12">
                          <div className="image">
                            <img src={eventDtls?.image} alt="" />
                          </div>
                        </div>
                        <div className="column col-lg-6 col-md-6 col-sm-12">
                          <h3>{eventDtls?.heading}</h3>

                          <p>{eventDtls?.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EventDetails
