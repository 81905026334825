import React from 'react'

const Contact: React.FC = () => {
  return (
    <section className="contact-one">
      <div className="auto-container">
        <div className="sec-title centered">
          <div className="sec-title_title">CONTACT INFO</div>
          <h2 className="sec-title_heading">Get in touch!</h2>
          <div className="sec-title_text">
            Reach out to us for expert construction solutions tailored to your
            needs. <br /> Our focus on safety, quality, and sustainability
            ensures your project's success and our community's well-being.
          </div>
        </div>
        <div className="row clearfix">
          <div className="contact-info-block col-lg-6 col-md-6 col-sm-12">
            <div className="contact-info-block_inner">
              {/* <span className="contact-info-block_icon fa-solid fa-location-dot fa-fw"></span> */}
              <h4 className="contact-info-block_heading">Head Office</h4>
              <div className="contact-info-block_text">
                Plot No. 109-110 Coral Studio-1st, Plot No. B-64 and B-65,
                Sahakar Marg <br />
                Near Doodh Mishthan Bhandar, Lal Kothi, Jaipur - 302015
                <br />
                <a href="tel:+91 7014292959" className="remove-underline">
                  {' '}
                  +91 7014292959
                </a>
                ,
                <a href="tel:+91 7678486258" className="remove-underline">
                  {' '}
                  +91 7678486258
                </a>
                <br />
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3558.4971171821107!2d75.79285807543859!3d26.887713976662038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDUzJzE1LjgiTiA3NcKwNDcnNDMuNiJF!5e0!3m2!1sen!2sin!4v1724129885275!5m2!1sen!2sin"
                  width="500"
                  height="300"
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="contact-info-block col-lg-6 col-md-6 col-sm-12">
            <div className="contact-info-block_inner">
              {/* <span className="contact-info-block_icon fa-solid fa-location-dot fa-fw"></span> */}
              <h4 className="contact-info-block_heading">Branch Office 1</h4>
              <div className="contact-info-block_text">
                D-26 Anand Vihar Railway Colony Jagatpura, Jaipur 302017
                <br />
                <a href="tel:+91 7014292959" className="remove-underline">
                  {' '}
                  +91 7014292959
                </a>
                ,
                <a href="tel:+91 7678486258" className="remove-underline">
                  {' '}
                  +91 7678486258
                </a>
                <br />
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3560.249393336004!2d75.84120537401535!3d26.83201896355632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db735d8288ed3%3A0x71d98e96ebe4f8e2!2ssoulmate%20Dzine%20And%20Development%20LLP!5e0!3m2!1sen!2sin!4v1709644778614!5m2!1sen!2sin"
                  width="500"
                  height="300"
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="contact-info-block col-lg-6 col-md-6 col-sm-12">
            <div className="contact-info-block_inner">
              {/* <span className="contact-info-block_icon fa-solid fa-location-dot fa-fw"></span> */}
              <h4 className="contact-info-block_heading">Branch Office 2</h4>
              <div className="contact-info-block_text">
                NH-62, Opp. Reliance Petrol Pump Suratgarh, Rajasthan 335804
                <br />
                <a href="tel:+91 7014292959" className="remove-underline">
                  {' '}
                  +91 7014292959
                </a>
                ,
                <a href="tel:+91 7678486258" className="remove-underline">
                  {' '}
                  +91 7678486258
                </a>
                <br />
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3478.81444905734!2d73.87902297453863!3d29.317118052666128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391673abc4655dff%3A0x4e2c2171d478a85!2sSDD%20City&#39;Phase%201st!5e0!3m2!1sen!2sin!4v1710301069552!5m2!1sen!2sin"
                  width="500"
                  height="300"
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
