import Breadcrumb from 'components/breadcrumb'
import Contact from 'components/contact'
import React from 'react'

const ContactPage: React.FC = () => {
  return (
    <>
      <Breadcrumb item="Contact Us" />
      <Contact />
    </>
  )
}

export default ContactPage
