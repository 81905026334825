import React from 'react'
import { useParams } from 'react-router-dom'
import Portfolios from 'components/portfolio'

const PortfolioPage: React.FC = () => {
  const { constType } = useParams()
  return <Portfolios serviceName={constType || ''} />
}

export default PortfolioPage
