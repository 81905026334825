import Breadcrumb from 'components/breadcrumb'
import EventDetails from 'components/event-details'
import React from 'react'

const EventDetailsPage = () => {
  return (
    <>
      <Breadcrumb item="Event Details" />
      <EventDetails />
    </>
  )
}

export default EventDetailsPage
