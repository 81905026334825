import { combineReducers, configureStore } from '@reduxjs/toolkit'
import projectsReducer from './reducers/projectSlice'
import authSlice from './reducers/authSlice'
import notificationSlice from './reducers/notificationSlice'
import servicesSlice from './reducers/servicesSlice'
import faqSlice from './reducers/faqSlice'
import testimonialSlice from './reducers/testimonialSlice'
import clientSlice from './reducers/clientSlice'
import adminSlice from './reducers/adminSlice'
import communicationSlice from './reducers/communicationSlice'
import eventSlice from './reducers/eventSlice'

const rootReducer = combineReducers({
  projects: projectsReducer,
  auth: authSlice,
  notification: notificationSlice,
  services: servicesSlice,
  faqs: faqSlice,
  testimonials: testimonialSlice,
  clients: clientSlice,
  admin: adminSlice,
  communication: communicationSlice,
  events: eventSlice,
})
export const store = configureStore({
  reducer: rootReducer,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
