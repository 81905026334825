import React from 'react'

const TeamDetails = () => {
  return (
    <section className="team-detail">
      <div className="auto-container">
        <div className="row clearfix">
          <div className="team-detail_info-column col-lg-3 col-md-12 col-sm-12">
            <div className="team-detail_info-inner">
              <div className="team-detail_author">
                <img src="assets/images/resource/team-4.jpg" alt="" />
              </div>
              <ul className="team-detail_author-info">
                <li>
                  <span>Position :</span> Site Manager
                </li>
                <li>
                  <span>Email :</span>{' '}
                  <a href="mailto:antilia@gmail.com">antilia@gmail.com</a>
                </li>
                <li>
                  <span>Contact: </span>{' '}
                  <a href="tel:+008-665-444-888">008 665 444 888</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="team-detail_content-column col-lg-9 col-md-12 col-sm-12">
            <div className="team-detail_content-inner">
              <h3 className="team-detail_title">All about Axeno</h3>
              <p>
                Must explain to you how all this mistaken idea of denouncing
                works pleasure and praising uts pain was born and I will gives
                you a itself completed account of the system, and sed expounds
                the ut actual teachings of that greater sed explores truth.
                Denouncing works pleasures and praising pains was us born and I
                will gives you a completed ut workers accounts of the system.
                sit aspernatur aut odit aut fugit, sed quia consequuntur magni
                dolores
              </p>
              <h3 className="team-detail_subtitle">Axeno Experience</h3>
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit, sed quia
                consequuntur magni{' '}
              </p>
              <p>
                Must explain to you how all this mistaken idea of denouncing
                works pleasure and praising uts pain was born and I will gives
                you a itself completed account of the system, and sed expounds
                the ut actual teachings of that greater sed explores truth.
                Denouncing works pleasures and praising pains was us born.
              </p>
              <div className="row clearfix">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <h4 className="team-detail_subtitle">Specialty</h4>
                  <p>
                    Leadership Development from nemo emin imsam dolor seti amet
                    colorner diteysy
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <h4 className="team-detail_subtitle">Degrees</h4>
                  <p>
                    M.D. of Business. Leadership Development from nemo emin
                    imsam dolor seti amet
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <h4 className="team-detail_subtitle">Training</h4>
                  <p>
                    dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                    voluptas sit aspernatur aut odit aut
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TeamDetails
