import Login from 'admin/components/login'
import Notification from 'admin/components/notification'
import React from 'react'

const LoginPage: React.FC = () => {
  return (
    <>
      <Notification />
      <Login />
    </>
  )
}

export default LoginPage
