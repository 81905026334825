import React from 'react'

const Intro = () => {
  return (
    <section className="welcome-three">
      <div className="auto-container">
        <div className="row clearfix">
          <div className="welcome-three_content-column col-lg-7 col-md-12 col-sm-12">
            <div className="welcome-three_content-outer">
              <div className="welcome-three_image">
                <img src="assets/images/resource/welcome-5.jpg" alt="" />
              </div>
              <div className="welcome-three_content">
                <div className="welcome-three_image-two">
                  <img src="assets/images/resource/welcome-6.jpg" alt="" />
                </div>
                <div className="welcome-three_title">
                  Welcome to Soulmate Dzine
                </div>
                <h2 className="welcome-three_heading">
                  where precision meets proficiency in plot surveying and
                  construction Expert
                </h2>
                <div className="row clearfix">
                  <div className="column col-lg-12 colm-md-12 col-sm-12">
                    <p>
                      Soulmate Dzine and Development LLP is an infrastructure
                      major with interests in Construction of and Urban
                      Infrastructure sectors. Founded in 2017, the Group is well
                      diversified and professionally managed. Employing the
                      Public Private Partnership model, the Group has
                      successfully implemented several infrastructure projects
                      in Rajasthan. We believe that people are the cornerstone
                      of the Group’s success, and the company rewards hard work,
                      knowledge, and commitment. The 7 Values & Beliefs seek to
                      provide a platform on which employees can perform and
                      deliver. The company’s human resources and benefits
                      programs are designed to attract and retain the best
                      employees. The organization endeavors to provide job
                      opportunities across businesses, depending upon company
                      needs and professional interest and competency of the
                      employees. If you are interested in working with us, there
                      are two ways to proceed. You can click current openings,
                      and apply for any of the various positions currently
                      available at the Soulmate Dzine and Development LLP .
                      Alternatively, if you don’t find the job you’re looking
                      for, you can still submit your resume with us.
                    </p>
                  </div>
                  {/* <div className="column col-lg-12 colm-md-12 col-sm-12">
                    <ul className="welcome-three_list">
                      <li>Highly skilled professionals.</li>
                      <li>Guaranteed top-notch results.</li>
                      <li>On-time project completion.</li>
                      <li>Trust-based client relationships.</li>
                    </ul>
                  </div> */}
                </div>
                <div className="welcome-three_author">
                  Ar. Mahinder Kumar <span>Partner</span>
                </div>
                {/* <div className="welcome-three_signature">
                  <img src="assets/images/icons/signature.png" alt="" />
                </div> */}
              </div>
            </div>
          </div>

          <div className="welcome-three_image-column col-lg-5 col-md-10 col-sm-12">
            <div className="welcome-three_image-outer">
              <div className="welcome-three_color_circle"></div>
              <div className="welcome-three_image-three skewElem">
                <img src="assets/images/resource/welcome-7.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Intro
