import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ILogin } from 'interface/ILogin'
import { GET, POST } from 'service'
import Constant from 'utils/constants'
import { setSessionDtls } from 'utils/utils'
import { showToast } from './notificationSlice'
import { AxiosError } from 'axios'
import { IError } from 'interface/IError'
import { setFaqs } from './faqSlice'
import { setTestimonials } from './testimonialSlice'
import { setTopProjects } from './projectSlice'
import { setServices } from './servicesSlice'
const constant = new Constant()
const initialState = {
  isLoading: false,
  hasAuth: false,
}

export const token = createAsyncThunk(
  'user/token',
  async (params: ILogin, thunkAPI) => {
    const { username, password } = params
    try {
      const result = await POST(
        constant.API_URLS.VALIDATE_USER,
        { username, password },
        {}
      )

      const { data: response } = result
      return JSON.stringify(response)
    } catch (err) {
      const error = err as AxiosError
      const data = error.response?.data as IError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: data?.message || error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(constant.ERROR_MSG.API_FAILED)
    }
  }
)
export const changePwd = createAsyncThunk(
  'user/changePwd',
  async (
    params: {
      oldPwd: string
      newPwd: string
      cnfPwd: string
      username: string
    },
    thunkAPI
  ) => {
    try {
      const result = await POST(constant.API_URLS.CHANGE_PWD, params, {})
      const { data: response } = result
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.SUCCESS,
          body: response?.message,
          variant: constant.TOAST_VARIANT.SUCCESS,
        })
      )

      return JSON.stringify(response)
    } catch (err) {
      const error = err as AxiosError
      const data = error.response?.data as IError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: data?.message || error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(constant.ERROR_MSG.API_FAILED)
    }
  }
)

export const getAllData = createAsyncThunk(
  'auth/getAllData',
  async (params, thunkAPI) => {
    try {
      const result = await GET(constant.API_URLS.GET_GUEST, {})
      thunkAPI.dispatch(setFaqs(result?.data?.faqs))
      thunkAPI.dispatch(setTestimonials(result?.data?.testimonials))
      thunkAPI.dispatch(setTopProjects(result?.data?.portfolios))
      thunkAPI.dispatch(setServices(result?.data?.services))
      return JSON.stringify(result)
    } catch (err) {
      const error = err as AxiosError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(null)
    }
  }
)

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(token.pending, (state) => {
        state.isLoading = true
      })
      .addCase(token.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(token.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.hasAuth = true
        const { token, email } = JSON.parse(payload)
        setSessionDtls({ token: token, emailId: email })
      })
      .addCase(getAllData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllData.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getAllData.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(changePwd.pending, (state) => {
        state.isLoading = true
      })
      .addCase(changePwd.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(changePwd.fulfilled, (state) => {
        state.isLoading = false
        state.hasAuth = false
        setSessionDtls({ token: '', emailId: '' })
      })
  },
})

export default authSlice.reducer
