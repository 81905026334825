import { ITestimonial } from 'interface/ITestimonial'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

const Testimonials = () => {
  const testimonials = useSelector<RootState>(
    (state) => state?.testimonials?.testimonials
  ) as Array<ITestimonial>

  const renderRating = (noOfStars: number) => {
    const stars: React.JSX.Element[] = []
    for (let index = 0; index < noOfStars; index++) {
      stars.push(<span key={`star_${index}`} className="fa fa-star"></span>)
    }
    return stars
  }

  const renderTestimonials = () => {
    return testimonials?.map(({ feedback, star, name, location }, id) => {
      return (
        <div key={`tt_${id}`} className="swiper-slide">
          <div className="testimonial-block_two">
            <div className="testimonial-block_two-inner">
              <div className="testimonial-block_two-content">
                <div className="testimonial-block_two-text">{feedback}</div>
                <div className="testimonial-block_two-rating">
                  {renderRating(star)}
                </div>
                <div className="testimonial-block_two-author">
                  {name}
                  <span>{location}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <section className="testimonial-three">
      <div className="testimonial-three_color-layer"></div>
      <div className="auto-container">
        <div className="sec-title">
          <div className="sec-title_title">Testimonials</div>
          <h2 className="sec-title_heading">Clients Reviews</h2>
        </div>
        <div className="inner-container">
          <div className="testimonial__slider-wrap">
            <div className="testimonial__active_two swiper-container">
              <div className="swiper-wrapper" id="testimonials">
                {renderTestimonials()}
              </div>
            </div>
            <div className="testimonial__nav_two swiper-container">
              <div className="swiper-wrapper"></div>
            </div>
            <div className="testimonial__nav-two-arrow">
              <div className="testimonial__nav-two-slider-prev">
                <img
                  src="assets/images/icons/client-one_prev-arrow.png"
                  alt=""
                />
              </div>
              <div className="testimonial__nav_two-slider-next">
                <img
                  src="assets/images/icons/client-one_next-arrow.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
