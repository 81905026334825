import { IImage } from 'interface/IImage'
import React from 'react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const Image: React.FC<IImage> = ({ src }) => {
  return (
    <Zoom>
      <img className="image-view" src={src} />
    </Zoom>
  )
}

export default Image
