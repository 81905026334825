import Breadcrumb from 'components/breadcrumb'
import Events from 'components/events'
import React from 'react'

const EventsPage = () => {
  return (
    <>
      <Breadcrumb item="Events" />
      <Events />
    </>
  )
}

export default EventsPage
