import React from 'react'

const AboutUs = () => {
  return (
    <section className="welcome-two">
      <div className="auto-container">
        <div className="sec-title d-flex justify-content-between align-items-center flex-wrap">
          <div className="left-box">
            <div className="sec-title_title">Welcome to SOULMATE DZINE</div>
            <h2 className="sec-title_heading">
              We use quality materials, <br /> natural and modern, making <br />{' '}
              buildings
            </h2>
          </div>
          <div className="circle_image">
            <img src="assets/images/resource/welcome-2.png" alt="" />
          </div>
        </div>
        <div className="row clearfix">
          <div className="welcome-two_content-column col-lg-5 col-md-12 col-sm-12">
            <div className="welcome-two_content-outer">
              <p>
                Soulmate Dzine and Development LLP is established in 2017,
                having vast experience of Building, Site-grading, Infrastructure
                and Turnkey Projects (With Material Projects) in Rajasthan, and
                enjoys high credibility on this account. We have experience to
                execute wide range of projects in Residential, Commercial,
                Industrial, institutional , Civil and Infrastructure, Building
                sectors, committed to quality and timely completion of projects.
                SDD works with engineers and architects to provide a range of
                services from new home construction to major retrofit projects.
                We at SDD deliver our services with complete professionalism
                backed by decades of experience. We take the time to estimate
                accurately, complete the work in time, within the estimate, and
                provide superior work quality. And all of this is handled by our
                dedicated and experienced team. We pride ourselves on solving
                problems to save our clients time and money.
              </p>

              <ul className="welcome-two_list">
                <li>Meticulous planning</li>
                <li>Quality material selection</li>
                <li>adherence to safety standards</li>
                <li>Skilled Labour</li>
              </ul>
              <div className="welcome-two_author">
                Ar. Mahinder Kumar <span>Partner</span>
              </div>
            </div>
          </div>
          <div className="welcome-two_image-column col-lg-7 col-md-12 col-sm-12">
            <div className="welcome-two_image-outer">
              <div className="welcome-two_image skewElem">
                <img src="assets/images/resource/welcome-3.jpg" alt="" />
              </div>
              <div
                className="welcome-two_experiance"
                data-parallax='{"y" : 40}'
              >
                <img src="assets/images/resource/welcome-4.jpg" alt="" />
                <div className="welcome-two_experiance-count">
                  <div className="count-box">
                    <span
                      className="count-text"
                      data-speed="3000"
                      data-stop="12"
                    >
                      0
                    </span>
                    <sub>+</sub>
                  </div>
                  <i>
                    Years of <br /> Experience
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
