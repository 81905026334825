import Clients from 'components/clients'
import importScript from 'hooks/import-script'
import { IService } from 'interface/IService'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { getAll } from 'store/reducers/servicesSlice'

const Services: React.FC = () => {
  importScript()
  const dispatch = useDispatch<AppDispatch>()
  const services = useSelector<RootState>(
    (state) => state?.services?.services
  ) as Array<IService>
  useEffect(() => {
    dispatch(getAll())
  }, [])
  const renderServices = () => {
    return services?.map(({ id, heading, imageUrl, content }) => {
      const headingLink = `/portfolio/${heading}`
      return (
        <div
          key={`service_${id}`}
          className="service-block_one col-lg-6 col-md-6 col-sm-12"
        >
          <div className="service-block_one-inner">
            <div className="service-block_one-upper">
              <div className="service-block_one-icon">
                <img src={imageUrl} alt="" />
              </div>
              <h5 className="service-block_one-heading">
                <Link to={headingLink}>{heading}</Link>
              </h5>
            </div>
            <div className="service-block_one-text">{content}</div>
          </div>
        </div>
      )
    })
  }
  return (
    <section className="services-one">
      <div
        className="services-one_image-layer"
        style={{
          backgroundImage: "url('assets/images/background/service-one_bg.jpg')",
        }}
      ></div>
      <div className="auto-container">
        <div className="sec-title d-flex justify-content-between align-items-center flex-wrap">
          <div className="left-box">
            <div className="sec-title_title">Company Services</div>
            <h2 className="sec-title_heading">
              We specialize in these constrcution <br /> fields with
              material/turnkey.
            </h2>
          </div>
          <div className="sec-title_text">
            With expertise across residential, commercial, land surveying,
            hospitality, and in-house projects, our company delivers tailored
            solutions to meet the unique requirements of every client and
            project.
          </div>
        </div>
        <div className="inner-container">
          <div className="services-one_circle-color"></div>
          <div className="row clearfix">
            <div className="services-one_blocks-column col-lg-8 col-md-12 col-sm-12">
              <div className="services-one_blocks-outer">
                <div className="row clearfix">{renderServices()}</div>
              </div>
            </div>
            <div className="services-one_video-column col-lg-4 col-md-8 col-sm-12">
              <div className="services-one_video-outer">
                <div className="service-block_one-video skewElem">
                  <img src="assets/images/resource/video.jpg" alt="" />
                  <a
                    href="https://www.youtube.com/watch?v=YS3PwmOQ1Fc"
                    className="lightbox-video services-one_play"
                  >
                    <span className="fa fa-play">
                      <i className="ripple"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Clients />
      </div>
    </section>
  )
}

export default Services
