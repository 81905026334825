import Faq from 'components/faq'
import Intro from 'components/intro'
import News from 'components/news'
import ProjectReview from 'components/project-review'
import ProjectTypes from 'components/project-types'
import Slider from 'components/slider'
import StayConnected from 'components/stay-connected'
import Testimonials from 'components/testimonials'
import importScript from 'hooks/import-script'
import React from 'react'

const HomePage = () => {
  importScript()
  return (
    <>
      <Slider />
      <Intro />
      <ProjectReview />
      <ProjectTypes />
      <StayConnected />
      <Faq />
      <News />
      <Testimonials />
    </>
  )
}

export default HomePage
