import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ITestimonialstore } from 'interface/ITestimonial'
import { DELETE, GET, POST } from 'service'
import Constant from 'utils/constants'
import { showToast } from './notificationSlice'
import { AxiosError } from 'axios'

const constant = new Constant()
const initialState: ITestimonialstore = {
  isLoading: false,
  testimonials: [],
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getAll = createAsyncThunk('testimonials/getAll', async () => {
  const result = await GET(constant.API_URLS.GET_TESTIMONIALS, {})
  return JSON.stringify(result)
})

export const addTestimonial = createAsyncThunk(
  'testimonials/add',
  async (params: object, thunkAPI) => {
    try {
      const result = await POST(constant.API_URLS.ADD_TESTIMONIALS, params, {})
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.SUCCESS,
          body: result?.data?.message,
          variant: constant.TOAST_VARIANT.SUCCESS,
        })
      )
      return JSON.stringify(result)
    } catch (err) {
      const error = err as AxiosError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(null)
    }
  }
)

export const deleteTestimonial = createAsyncThunk(
  'testimonials/deleteTestimonial',
  async (id: number, thunkAPI) => {
    try {
      const result = await DELETE(
        constant.API_URLS.DELETE_TESTIMONIALS.replace('$id', id.toString()),
        {}
      )
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.SUCCESS,
          body: result?.data?.message,
          variant: constant.TOAST_VARIANT.SUCCESS,
        })
      )
      thunkAPI.dispatch(getAll())
      return JSON.stringify(result)
    } catch (err) {
      const error = err as AxiosError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(null)
    }
  }
)

export const testimonialSlice = createSlice({
  name: 'testimonials',
  initialState,
  reducers: {
    setTestimonials: (state, { payload }) => {
      state.testimonials = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAll.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAll.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getAll.fulfilled, (state, { payload }) => {
        state.isLoading = false
        const parsedPayload = JSON.parse(payload)
        state.testimonials = parsedPayload.data
      })
      .addCase(addTestimonial.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addTestimonial.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(addTestimonial.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(deleteTestimonial.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteTestimonial.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(deleteTestimonial.fulfilled, (state) => {
        state.isLoading = false
      })
  },
})

export const { setTestimonials } = testimonialSlice.actions

export default testimonialSlice.reducer
