import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilVolumeHigh,
  cilFilterFrames,
  cilIndustry,
  cilBuilding,
  cilUser,
  cilSpeedometer,
  cilNewspaper,
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

const adminTo = '/admin'
const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/admin',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },

  {
    component: CNavTitle,
    name: 'Pages',
  },
  {
    component: CNavGroup,
    name: 'Clients',
    to: `${adminTo}/client`,
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Add Client',
        to: `${adminTo}/client/add`,
      },
      {
        component: CNavItem,
        name: 'Edit/Delete Clients',
        to: `${adminTo}/client/view`,
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Events',
    to: `${adminTo}/events`,
    icon: <CIcon icon={cilNewspaper} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Add Event',
        to: `${adminTo}/events/add`,
      },
      {
        component: CNavItem,
        name: 'Edit/Delete Events',
        to: `${adminTo}/events/view`,
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Services',
    to: `${adminTo}/services`,
    icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Add Service',
        to: `${adminTo}/services/add`,
      },
      {
        component: CNavItem,
        name: 'Edit/Delete Services',
        to: `${adminTo}/services/view`,
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Portfolios',
    to: `${adminTo}/portfolios`,
    icon: <CIcon icon={cilIndustry} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Add Portfolio',
        to: `${adminTo}/portfolios/add`,
      },
      {
        component: CNavItem,
        name: 'Edit/Delete Portfolio',
        to: `${adminTo}/portfolios/view`,
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Faqs',
    to: `${adminTo}/faqs`,
    icon: <CIcon icon={cilFilterFrames} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Add faq',
        to: `${adminTo}/faqs/add`,
      },
      {
        component: CNavItem,
        name: 'Edit/Delete faqs',
        to: `${adminTo}/faqs/view`,
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Testimonials',
    to: `${adminTo}/testimonials`,
    icon: <CIcon icon={cilVolumeHigh} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Add Testimonial',
        to: `${adminTo}/testimonials/add`,
      },
      {
        component: CNavItem,
        name: 'Edit/Delete Testimonials',
        to: `${adminTo}/testimonials/view`,
      },
    ],
  },
]

export default _nav
