import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IClientStore } from 'interface/IClient'
import { DELETE, GET, POST } from 'service'
import Constant from 'utils/constants'
import { showToast } from './notificationSlice'
import { AxiosError } from 'axios'

const constant = new Constant()
const initialState: IClientStore = {
  isLoading: false,
  clients: [],
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getAll = createAsyncThunk('clients/getAll', async () => {
  const result = await GET(constant.API_URLS.GET_CLIENTS, {})
  return JSON.stringify(result)
})

export const addClient = createAsyncThunk(
  'clients/addClient',
  async (params: object, thunkAPI) => {
    try {
      const result = await POST(constant.API_URLS.ADD_CLIENT, params, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.SUCCESS,
          body: result?.data?.message,
          variant: constant.TOAST_VARIANT.SUCCESS,
        })
      )
      return JSON.stringify(result)
    } catch (err) {
      const error = err as AxiosError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(null)
    }
  }
)
export const deleteClient = createAsyncThunk(
  'clients/deleteClient',
  async (id: number, thunkAPI) => {
    try {
      const result = await DELETE(
        constant.API_URLS.DELETE_CLIENT.replace('$id', id.toString()),
        {}
      )
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.SUCCESS,
          body: result?.data?.message,
          variant: constant.TOAST_VARIANT.SUCCESS,
        })
      )
      thunkAPI.dispatch(getAll())
      return JSON.stringify(result)
    } catch (err) {
      const error = err as AxiosError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(null)
    }
  }
)

export const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAll.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAll.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getAll.fulfilled, (state, { payload }) => {
        const parsedPayload = JSON.parse(payload)
        state.isLoading = false
        state.clients = parsedPayload.data
      })
      .addCase(addClient.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addClient.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(addClient.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(deleteClient.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteClient.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(deleteClient.fulfilled, (state) => {
        state.isLoading = false
      })
  },
})

export default clientSlice.reducer
