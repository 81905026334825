import IHeader from 'interface/IHeader'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { RootState } from 'store'
import Constant from 'utils/constants'

const Header: React.FC<IHeader> = ({ backToTop }) => {
  const constant = new Constant()
  const location = useLocation()

  const services = useSelector<RootState>(
    (state) => state.services?.serviceNames
  ) as Array<{ heading: string }>

  useEffect(() => {
    backToTop?.current && backToTop?.current.click()
  }, [location])

  const renderPortfolioMenus = () => {
    return services?.map((service) => (
      <li>
        <Link to={`/portfolio/${service?.heading}`}>{service?.heading}</Link>
      </li>
    ))
  }

  return (
    <>
      <header className="main-header">
        <div className="header-top">
          <div className="auto-container">
            <div className="inner-container">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="header-top_text">
                  Construction: planning, building, inspection, occupancy.
                </div>

                <div className="right-box d-flex align-items-center flex-wrap">
                  <ul className="header-top_list">
                    <li>
                      <span className="icon">
                        <img src="assets/images/icons/phone.png" alt="" />
                      </span>
                      <a href="tel:+91 7014292959"> +91 7014292959</a>
                      <a href="tel:+91 7678486258"> +91 7678486258</a>
                    </li>
                  </ul>
                  <div className="header_socials">
                    <span>Follow Us :</span>
                    <a href={constant.SOCIAL_NETWORKS_URL.FB} target="_blank">
                      <img src="assets/images/resource/fb.png" alt="" />
                    </a>
                    <a
                      href={constant.SOCIAL_NETWORKS_URL.TWITTER}
                      target="_blank"
                    >
                      <img src="assets/images/resource/x.png" alt="" />
                    </a>
                    <a
                      href={constant.SOCIAL_NETWORKS_URL.YOUTUBE}
                      target="_blank"
                    >
                      <img src="assets/images/resource/youtube.png" alt="" />
                    </a>
                    <a
                      href={constant.SOCIAL_NETWORKS_URL.INSTA}
                      target="_blank"
                    >
                      <img src="assets/images/resource/insta.png" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-upper">
          <div className="auto-container">
            <div className="inner-container">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="logo-box">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src="assets/images/SDD-LOGO-low.png"
                        alt=""
                        title=""
                      />
                      <p className="logo_text">SOULMATE DZINE</p>
                    </Link>
                  </div>
                  <div className="logo-2">
                    <Link to="/">
                      <img
                        src="assets/images/SDD-LOGO-low.png"
                        alt=""
                        title=""
                      />
                      <p className="logo_text">SOULMATE DZINE</p>
                    </Link>
                  </div>
                </div>

                <div className="nav-outer">
                  <nav className="main-menu navbar-expand-md">
                    <div className="navbar-header">
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </button>
                    </div>

                    <div
                      className="navbar-collapse collapse clearfix"
                      id="navbarSupportedContent"
                    >
                      <ul className="navigation clearfix">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/aboutus">About</Link>
                        </li>
                        <li>
                          <Link to="/services">Services</Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/">Portfolio</Link>
                          <ul>{renderPortfolioMenus()}</ul>
                        </li>
                        <li>
                          <Link to="/events">Events</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>

                <div className="outer-box d-flex align-items-center flex-wrap">
                  <div className="search-box-btn search-box-outer">
                    <span className="icon fa fa-search"></span>
                  </div>

                  <div className="header_button-box">
                    <a
                      href={constant.WHATS_APP_URL}
                      target="_blank"
                      className="theme-btn btn-style-one"
                    >
                      <span className="btn-wrap">
                        <span className="text-one">Get In Touch</span>
                        <span className="text-two">Get In Touch</span>
                      </span>
                    </a>
                  </div>

                  <div className="mobile-nav-toggler">
                    <span className="icon flaticon-menu"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-menu">
          <div className="menu-backdrop"></div>
          <div className="close-btn">
            <span className="icon flaticon-close-1"></span>
          </div>

          <nav className="menu-box">
            <div className="nav-logo">
              <Link to="/">
                <img src="assets/images/SDD-LOGO-low.png" alt="" title="" />
              </Link>
              <p className="logo_text">SOULMATE DZINE</p>
            </div>
            <div className="menu-outer"></div>
          </nav>
        </div>
      </header>
      <section className="offcanvas__area">
        <div
          className="close_offcanvas close-side-widget flaticon-cancel-1"
          id="close_offcanvas"
        ></div>
      </section>
      <div className="search-popup">
        <div className="color-layer"></div>
        <button className="close-search">
          <span className="flaticon-close-1"></span>
        </button>
        <form method="post" action="#">
          <div className="form-group">
            <input
              type="search"
              name="search-field"
              value=""
              placeholder="Search Here"
              required
            />
            <button
              className="fa fa-solid fa-magnifying-glass fa-fw"
              type="submit"
            ></button>
          </div>
        </form>
      </div>
    </>
  )
}

export default Header
