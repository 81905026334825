import { IFaq } from 'interface/IFaq'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { sendEmail } from 'store/reducers/communicationSlice'
import Constant from 'utils/constants'

interface IEnquire {
  name: string
  email: string
  phone: string
  enquireFor: string
  message: string
}

const defaultState = {
  name: '',
  email: '',
  phone: '',
  enquireFor: '',
  message: '',
}

const Faq = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [formData, setFormData] = useState<IEnquire>(defaultState)
  const constant = new Constant()
  const faqs = useSelector<RootState>(
    (state) => state?.faqs?.faqs
  ) as Array<IFaq>

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    setFormData({ ...formData, [name]: value })
  }

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = () => {
    const regex = new RegExp(/\S+@\S+\.\S+/)
    const isValidEmail = regex.test(formData?.email)
    if (
      isValidEmail &&
      formData?.name &&
      formData?.email &&
      formData?.message &&
      formData?.phone
    ) {
      const params = {
        from: 'support@soulmatedzine.com',
        to: 'info@soulmatedzine.com',
        subject: `Enquire for ${formData?.enquireFor || 'Residentials '} By ${formData?.name}`,
        html: `
        <table border="1" cellpadding="0" cellspacing="0">
<thead>
  <tr>
    <th >Name</th>
    <th >Email</th>
    <th >Phone</th>
    <th >Enquire For</th>
    <th >message</th>
  </tr>
</thead>
<tbody>
  <tr>
    <td >${formData?.name}</td>
    <td >${formData?.email}</td>
    <td >${formData?.phone}</td>
    <td >${formData?.enquireFor}</td>
    <td >${formData?.message}</td>
  </tr>
</tbody>
</table>`,
      }
      dispatch(sendEmail(params))
    }
  }

  const renderFaqs = () => {
    const questions = faqs?.map(({ question, answer }, idx) => {
      return (
        <li className={`accordion block ${idx === 0 ? 'active-block' : ''}`}>
          <div className={`acc-btn ${idx === 0 ? 'active' : ''}`}>
            <div className="icon-outer">
              <span className="icon icon-plus fa fa-plus"></span>{' '}
              <span className="icon icon-minus fa fa-minus"></span>
            </div>
            {question}
          </div>
          <div className={`acc-content ${idx === 0 ? 'current' : ''}`}>
            <div className="content">
              <div className="text">{answer}</div>
            </div>
          </div>
        </li>
      )
    })
    return <ul className="accordion-box">{questions}</ul>
  }
  return (
    <section className="faq-one">
      <div
        className="faq-one_image-layer"
        style={{
          backgroundImage: "url('assets/images/background/faq-one_bg.jpg')",
        }}
      ></div>
      <div
        className="faq-one_image-layer-two"
        style={{
          backgroundImage: "url('assets/images/background/faq-one_bg-two.jpg')",
        }}
      ></div>
      <div className="auto-container">
        <div className="row clearfix">
          <div className="faq-one_accordian-column col-lg-7 col-md-12 col-sm-12">
            <div
              className="faq-one_accordian-outer wow fadeInLeft"
              data-wow-delay="0ms"
              data-wow-duration="1500ms"
            >
              <div className="sec-title">
                <div className="sec-title_title">Helpful Faqs</div>
                <h2 className="sec-title_heading">Planning Guide!</h2>
              </div>
              {renderFaqs()}
            </div>
          </div>
          <div className="faq-one_form-column col-lg-5 col-md-8 col-sm-12">
            <div
              className="faq-one_form-outer wow fadeInRight"
              data-wow-delay="0ms"
              data-wow-duration="1500ms"
            >
              <div
                className="faq-one_form-vector"
                style={{
                  backgroundImage:
                    "url('assets/images/background/faq-one_vector.png')",
                }}
              ></div>
              <h5>Request a Quote</h5>

              <div className="default-form">
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    placeholder="Complete Name"
                    required
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    required
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone No"
                    required
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <select name="enquireFor" onChange={handleSelectChange}>
                    <option>Project Type</option>
                    <option value={constant.CONSTRUCTION_TYPE.RESIDENTIAL}>
                      {constant.CONSTRUCTION_TYPE.RESIDENTIAL}
                    </option>
                    <option value={constant.CONSTRUCTION_TYPE.CIVIL_WORK}>
                      {constant.CONSTRUCTION_TYPE.CIVIL_WORK}
                    </option>
                    <option value={constant.CONSTRUCTION_TYPE.COMMERCIAL}>
                      {constant.CONSTRUCTION_TYPE.COMMERCIAL}
                    </option>
                    <option value={constant.CONSTRUCTION_TYPE.INHOUSE}>
                      {constant.CONSTRUCTION_TYPE.INHOUSE}
                    </option>
                    <option value={constant.CONSTRUCTION_TYPE.LAND_SURVEY}>
                      {constant.CONSTRUCTION_TYPE.LAND_SURVEY}
                    </option>
                    <option
                      value={constant.CONSTRUCTION_TYPE.TOPOGRAPHICAL_SURVEY}
                    >
                      {constant.CONSTRUCTION_TYPE.TOPOGRAPHICAL_SURVEY}
                    </option>
                    <option
                      value={constant.CONSTRUCTION_TYPE.TOWNSHIP_LAND_DESIGN}
                    >
                      {constant.CONSTRUCTION_TYPE.TOWNSHIP_LAND_DESIGN}
                    </option>
                  </select>
                </div>

                <div className="form-group">
                  <textarea
                    className=""
                    name="message"
                    placeholder="Enter your Message"
                    onChange={handleTextAreaChange}
                  ></textarea>
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="theme-btn btn-style-two"
                  >
                    <span className="btn-wrap">
                      <span className="text-one">submit now</span>
                      <span className="text-two">submit now</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Faq
