import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react'
import { IRoute } from 'interface/IRoute'
import { adminRoutes as routes } from 'routes'
import { IBreadcrumb } from 'admin/interfaces/IBreadcrumb'

const AdminBreadcrumb = () => {
  const currentLocation = useLocation().pathname
  const getRouteName = (pathname: string, routes: Array<IRoute>) => {
    const currentRoute = routes.find((route) => route.path === pathname)
    return currentRoute ? currentRoute.name : false
  }

  const getBreadcrumbs = (location: string) => {
    const breadcrumbs: Array<IBreadcrumb> = []
    location.split('/').reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`
      const routeName = getRouteName(currentPathname, routes)
      routeName &&
        breadcrumbs.push({
          pathname: currentPathname,
          name: routeName,
          active: index + 1 === array.length ? true : false,
        })
      return currentPathname
    })
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (
    <CBreadcrumb className="my-0">
      {breadcrumbs.map((breadcrumb, index) => {
        return breadcrumb.active ? (
          <CBreadcrumbItem active={true} key={index}>
            {breadcrumb.name}
          </CBreadcrumbItem>
        ) : (
          <>
            <Link to={breadcrumb.pathname}>{breadcrumb.name}</Link>&nbsp; /
            &nbsp;{' '}
          </>
        )
      })}
    </CBreadcrumb>
  )
}

export default React.memo(AdminBreadcrumb)
