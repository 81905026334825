import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { POST } from 'service'
import Constant from 'utils/constants'
import { showToast } from './notificationSlice'
import { AxiosError } from 'axios'
import { IError } from 'interface/IError'

const initialState = {
  isLoading: false,
}
const constant = new Constant()
export const sendEmail = createAsyncThunk(
  'communication/sendEmail',
  async (
    params: {
      from: string
      to: string
      subject: string
      html: string
    },
    thunkAPI
  ) => {
    try {
      const result = await POST(constant.API_URLS.SEND_MAIL, params, {})
      const { data: response } = result
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.SUCCESS,
          body: response?.message,
          variant: constant.TOAST_VARIANT.SUCCESS,
        })
      )

      return JSON.stringify(response)
    } catch (err) {
      const error = err as AxiosError
      const data = error.response?.data as IError
      thunkAPI.dispatch(
        showToast({
          title: constant.TOAST_TITLE.ERROR,
          body: data?.message || error?.message,
          variant: constant.TOAST_VARIANT.DANGER,
        })
      )
      return thunkAPI.rejectWithValue(constant.ERROR_MSG.API_FAILED)
    }
  }
)

export const communicationSlice = createSlice({
  name: 'communication',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(sendEmail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(sendEmail.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(sendEmail.fulfilled, (state) => {
        state.isLoading = false
      })
  },
})

export default communicationSlice.reducer
