import { createSlice } from '@reduxjs/toolkit'
import { INotification } from 'admin/interfaces/INotification'
const initialState: INotification = {
  title: '',
  body: '',
  variant: '',
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showToast: (state, { payload }) => {
      const { title, body, variant } = payload as INotification
      state.title = title
      state.body = body
      state.variant = variant
    },
    closeToast: () => initialState,
  },
})

export const { showToast, closeToast } = notificationSlice.actions

export default notificationSlice.reducer
