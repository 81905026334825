import { IProject } from 'interface/IProjects'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from 'store'
import { getDateTime } from 'utils/utils'

const ProjectReview = () => {
  const projects = useSelector<RootState>(
    (state) => state?.projects?.TopProjects
  ) as Array<IProject>

  const renderProjects = () => {
    return projects?.map(
      ({ id, title, image, heading, completedDate, service }) => {
        const headingLink = `/portfolio/${service?.heading}/${id}/${heading}`
        return (
          <div
            key={`p_${id}`}
            className="project-block_three col-xl-3 col-lg-6 col-md-6 col-sm-6"
          >
            <div className="project-block_three-inner">
              <div className="project-block_three-image">
                <img src={image} alt="" />
                <div className="project-block_three-overlay">
                  <div className="project-block_three-content">
                    <div className="project-block_three-title">{title}</div>
                    <h5 className="project-block_three-heading">
                      <Link to={headingLink}>{heading}</Link>
                    </h5>
                    <div className="project-block_three-date">
                      {getDateTime(completedDate)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    )
  }
  return (
    <section className="project-three">
      <div className="auto-container">
        <div className="sec-title centered">
          <div className="sec-title_icon">
            <img src="assets/images/icons/logo-icon.png" alt="" />
          </div>
          <div className="sec-title_title">Projects Preview</div>
          <h2 className="sec-title_heading">Latest Studio Projects</h2>
        </div>
        <div className="upper-box">
          <div className="row clearfix">{renderProjects()}</div>
        </div>
      </div>
    </section>
  )
}

export default ProjectReview
