import Breadcrumb from 'components/breadcrumb'
import Teams from 'components/teams'
import React from 'react'

const TeamPage = () => {
  return (
    <>
      <Breadcrumb item="Our Team" />
      <Teams />
    </>
  )
}

export default TeamPage
