import { CSpinner } from '@coreui/react'
import AdminFooter from 'admin/components/footer'
import AdminHeader from 'admin/components/header'
import Notification from 'admin/components/notification'
import Sidebar from 'admin/components/sidebar'
import LoginPage from 'admin/pages/login'
import Footer from 'components/footer'
import Header from 'components/header'
import NotFound from 'components/not-found'
import importScript from 'hooks/import-script'
import { IRoute } from 'interface/IRoute'
import AboutUsPage from 'pages/aboutus'
import ContactPage from 'pages/contact'
import EventDetailsPage from 'pages/event-details'
import EventsPage from 'pages/events'
import HomePage from 'pages/home'
import PortfolioPage from 'pages/portfolio'
import ProjectDetailsPage from 'pages/project-details'
import ServicePage from 'pages/services'
import TeamDetailsPage from 'pages/team-details'
import TeamPage from 'pages/teams'
import React, { Suspense, useEffect, useRef } from 'react'
import { Provider, useDispatch } from 'react-redux'
import { HashRouter, Outlet, Route, Routes, Navigate } from 'react-router-dom'
import { adminRoutes } from 'routes'
import { AppDispatch, store } from 'store'
import { getAllData } from 'store/reducers/authSlice'
import { isAuth } from 'utils/utils'

const App: React.FC = () => {
  importScript()
  const refBackToTop = useRef<HTMLDivElement>(null)

  const UserLayout: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    useEffect(() => {
      dispatch(getAllData())
    }, [])
    return (
      <>
        <Header backToTop={refBackToTop} />
        <Notification />
        <Outlet />
        <Footer backToTop={refBackToTop} />
      </>
    )
  }
  const AdminLayout: React.FC = () => {
    if (!isAuth()) {
      return <Navigate to={'/login'} />
    }
    return (
      <div>
        <Sidebar />
        <div className="wrapper d-flex flex-column min-vh-100">
          <AdminHeader />
          <div className="body flex-grow-1">
            <Notification />
            <Outlet />
          </div>

          <AdminFooter />
        </div>
      </div>
    )
  }

  return (
    <Provider store={store}>
      <HashRouter>
        <Suspense
          fallback={
            <div className="pt-3 text-center">
              <CSpinner color="primary" variant="grow" />
            </div>
          }
        >
          <Routes>
            <Route element={<UserLayout />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/aboutus" element={<AboutUsPage />} />
              <Route path="/services" element={<ServicePage />} />
              <Route path="/teams" element={<TeamPage />} />
              <Route path="/team-details" element={<TeamDetailsPage />} />
              <Route path="/events" element={<EventsPage />} />
              <Route
                path="/event-details/:eventId"
                element={<EventDetailsPage />}
              />
              <Route path="/portfolio/:constType" element={<PortfolioPage />} />
              <Route
                path="/portfolio/:constType/:pId/:pName"
                element={<ProjectDetailsPage />}
              />
              <Route path="*" element={<NotFound />} />
            </Route>
            /* Admin routes */
            <Route element={<AdminLayout />}>
              {adminRoutes?.map((route: IRoute) => {
                return <Route path={route?.path} element={route?.element} />
              })}
            </Route>
            <Route path="/login" element={<LoginPage />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </Provider>
  )
}

export default App
