import Breadcrumb from 'components/breadcrumb'
import ProjectDetails from 'components/project-details'
import { IProject } from 'interface/IProjects'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { getProjectById, projectStoreReset } from 'store/reducers/projectSlice'

const ProjectDetailsPage = () => {
  const { constType, pName, pId } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const projectDtls = useSelector<RootState>(
    (state) => state?.projects?.projectDtls
  ) as IProject
  useEffect(() => {
    if (!pId) {
      return
    }
    dispatch(getProjectById(pId))
    return () => {
      dispatch(projectStoreReset())
    }
  }, [])
  return (
    <>
      <Breadcrumb
        parentItem={constType}
        parentLink={`/portfolio/${constType}`}
        item={pName}
        bgImage={projectDtls?.image}
      />
      <ProjectDetails constType={constType} projectDtls={projectDtls} />
    </>
  )
}

export default ProjectDetailsPage
