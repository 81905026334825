import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import { IModal } from 'admin/interfaces/IModal'
import React from 'react'

const Modal: React.FC<IModal> = ({ title, visible, body, onClose }) => {
  return (
    <CModal visible={visible} onClose={onClose} size="lg">
      <CModalHeader>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>{body}</CRow>
      </CModalBody>
    </CModal>
  )
}

export default Modal
