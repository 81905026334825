import { IProject } from 'interface/IProjects'
import { IService } from 'interface/IService'

class Constant {
  WHATS_APP_URL: string = 'https://wa.me/7014292959'
  API_HOST_URL: string = process.env.REACT_API_URL || ''
  CONSTRUCTION_TYPE = {
    RESIDENTIAL: 'Residential',
    COMMERCIAL: 'Commercial',
    CIVIL_WORK: 'Civil Work',
    TOPOGRAPHICAL_SURVEY: 'Topographical Survey',
    TOWNSHIP_LAND_DESIGN: 'Township Land Design',
    INHOUSE: 'In-House',
    LAND_SURVEY: 'Land-Survey',
  }

  HTTP_STATUS = {
    OK: 200,
  }
  SOCIAL_NETWORKS_URL = {
    TWITTER: 'http://twitter.com/soulmatedzine',
    FB: 'https://www.facebook.com/profile.php?id=100089019305633&mibextid=dGKdO6',
    YOUTUBE: 'https://youtube.com/@Soulmatedzine?si=YWlMLMPOhZPTfsY8',
    INSTA: '',
  }

  TEAMS = [
    {
      name: 'Alan Cooper',
      designation: 'Architectural engineering',
      social: {
        fb: '#',
        twitter: '#',
        insta: '#',
      },
      image: 'assets/images/resource/team-1.jpg',
    },
    {
      name: 'Alan Cooper',
      designation: 'Architectural engineering',
      social: {
        fb: '#',
        twitter: '#',
        insta: '#',
      },
      image: 'assets/images/resource/team-2.jpg',
    },
    {
      name: 'Alan Cooper',
      designation: 'Architectural engineering',
      social: {
        fb: '#',
        twitter: '#',
        insta: '#',
      },
      image: 'assets/images/resource/team-3.jpg',
    },
    {
      name: 'Alan Cooper',
      designation: 'Architectural engineering',
      social: {
        fb: '#',
        twitter: '#',
        insta: '#',
      },
      image: 'assets/images/resource/team-4.jpg',
    },
  ]

  EVENTS = [
    {
      id: 1,
      image: 'assets/images/resource/news-3.jpg',
      date: 24,
      month: 'mar',
      title: 'Innovation',
      heading: 'World’s Largest Passive House Buildings',
    },
    {
      id: 2,
      image: 'assets/images/resource/news-3.jpg',
      date: 24,
      month: 'mar',
      title: 'Innovation',
      heading: 'World’s Largest Passive House Buildings',
    },
    {
      id: 3,
      image: 'assets/images/resource/news-3.jpg',
      date: 24,
      month: 'mar',
      title: 'Innovation',
      heading: 'World’s Largest Passive House Buildings',
    },
  ]

  API_URLS = {
    GET_PROJECTS_BY_SERVICE_NAME:
      'portfolio/getAllByServiceName?serviceName=$serviceName',
    ADD_PROJECT: 'portfolio/create',
    UPDATE_PROJECT: 'portfolio/update',
    DELETE_PROJECT: 'portfolio/deleteById/$id',
    SEND_MAIL: 'mail/sendEmail',
    GET_PROJECT: 'portfolio/getAll',
    GET_PROJECT_BY_ID: 'portfolio/getById/$id',
    GET_TOP_8_PROJECTS: 'portfolio/getAll',
    GET_SERVICES: 'services/getAll',
    ADD_SERVICES: 'services/create',
    UPDATE_SERVICES: 'services/update',
    DELETE_SERVICES: 'services/deleteById/$id',
    GET_FAQS: 'faqs/getAll',
    GET_EVENTS: 'events/getAll',
    ADD_EVENTS: 'events/create',
    UPDATE_EVENTS: 'events/update',
    DELETE_EVENTS: 'events/deleteById/$id',
    GET_EVENT_BY_ID: 'events/getById/$id',
    ADD_FAQS: 'faqs/create',
    DELETE_FAQS: 'faqs/deleteById/$id',
    GET_TESTIMONIALS: 'testimonials/getAll',
    ADD_TESTIMONIALS: 'testimonials/create',
    DELETE_TESTIMONIALS: 'testimonials/deleteById/$id',
    GET_CLIENTS: 'clients/getAll',
    ADD_CLIENT: 'clients/create',
    DELETE_CLIENT: 'clients/deleteById/$id',
    VALIDATE_USER: 'auth/token',
    CHANGE_PWD: 'auth/changePwd',
    GET_GUEST: 'auth/guest',
  }

  ERROR_MSG = {
    API_FAILED: 'Something went wrong',
  }

  TOAST_TITLE = {
    ERROR: 'Error',
    SUCCESS: 'Success',
  }

  TOAST_VARIANT = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    SUCCESS: 'success',
    DANGER: 'danger',
    WARN: 'warning',
    INFO: 'info',
    LIGHT: 'light',
    DARK: 'dark',
  }

  PROJECTS: Array<IProject> = []
  SERVICES: Array<IService> = []
}

export default Constant
