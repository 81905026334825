import { CFooter } from '@coreui/react'
import React from 'react'

const AdminFooter: React.FC = () => {
  return (
    <CFooter className="px-4">
      <div>
        <a href="#" target="_blank" rel="noopener noreferrer">
          Soulmate dzine
        </a>
        <span className="ms-1">&copy; 2024 SoulmateDzine.</span>
      </div>
    </CFooter>
  )
}

export default AdminFooter
