import React from 'react'
import { Link } from 'react-router-dom'

import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'

import { CBadge, CNavLink, CSidebarNav } from '@coreui/react'
import { ICommonNavItem, INavItem } from 'admin/interfaces/INavItem'
import Navigations from './navItems'

export const SidebarNav = () => {
  const items = Navigations as unknown as Array<INavItem>
  const navLink = (item: ICommonNavItem, indent = false) => {
    const { name, icon, badge } = item
    return (
      <>
        {icon
          ? icon
          : indent && (
              <span className="nav-icon">
                <span className="nav-icon-bullet"></span>
              </span>
            )}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    )
  }

  const navItem = (item: ICommonNavItem, index: number, indent = false) => {
    const { component, ...rest } = item
    const Component = component
    return (
      <Component as="div" key={index}>
        {rest?.to || rest?.href ? (
          <CNavLink {...(rest.to && { as: Link })} {...rest}>
            {navLink(item, indent)}
          </CNavLink>
        ) : (
          navLink(item, indent)
        )}
      </Component>
    )
  }

  const navGroup = (item: INavItem, index: number) => {
    const { component, ...rest } = item
    const Component = component
    return (
      <Component compact as="div" key={index} toggler={navLink(item)} {...rest}>
        {item.items?.map((i, index) =>
          i?.items ? navGroup(i, index) : navItem(i, index, true)
        )}
      </Component>
    )
  }

  return (
    <CSidebarNav as={SimpleBar}>
      {items &&
        items.map((item: INavItem, index: number) =>
          item.items ? navGroup(item, index) : navItem(item, index)
        )}
    </CSidebarNav>
  )
}

export default SidebarNav
