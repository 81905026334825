import React from 'react'
import { Link } from 'react-router-dom'
import Constant from 'utils/constants'

const Teams = () => {
  const constant = new Constant()
  const renderTeams = () => {
    return constant?.TEAMS?.map((team) => {
      return (
        <div className="team-block_one col-lg-4 col-md-6 col-sm-12">
          <div className="team-block_one-inner">
            <div className="team-block_one-image">
              <img src={team?.image} alt="" />

              <div className="team-block_one-socials">
                <a href={team?.social?.fb}>
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
                <a href={team?.social?.twitter}>
                  <i className="fa-brands fa-twitter"></i>
                </a>
                <a href={team?.social?.insta}>
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </div>
            </div>
            <div className="team-block_one-content">
              <h3 className="team-block_one-heading">
                <Link to="/team-details">{team?.name}</Link>
              </h3>
              <div className="team-block_one-designation">
                {team?.designation}
              </div>
            </div>
          </div>
        </div>
      )
    })
  }
  return (
    <>
      <section className="team-one">
        <div className="auto-container">
          <div className="row clearfix">
            {renderTeams()}

            <div className="team-one_button text-center">
              <a href="team.html" className="theme-btn btn-style-two">
                <span className="btn-wrap">
                  <span className="text-one">View More Team Members</span>
                  <span className="text-two">View More Team Members</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Teams
