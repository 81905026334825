import { IRoute } from 'interface/IRoute'
import React from 'react'

const DashboardPage = React.lazy(() => import('admin/pages/dashboard'))
const AddClientPage = React.lazy(() => import('admin/pages/client/add-client'))
const ViewClientsPage = React.lazy(() => import('admin/pages/client'))
const AddTestimonialPage = React.lazy(
  () => import('admin/pages/testimonials/add-testimonials')
)
const ViewTestimonialPage = React.lazy(() => import('admin/pages/testimonials'))
const AddFaqPage = React.lazy(() => import('admin/pages/faqs/add-faq'))
const ViewFaqsPage = React.lazy(() => import('admin/pages/faqs'))
const AddEventPage = React.lazy(() => import('admin/pages/event/add-event'))
const ViewEventPage = React.lazy(() => import('admin/pages/event'))
const AddServicePage = React.lazy(
  () => import('admin/pages/services/add-service')
)
const ViewServicePage = React.lazy(() => import('admin/pages/services'))
const AddPortfolioPage = React.lazy(
  () => import('admin/pages/portfolios/add-portfolio')
)
const ViewPortfolioPage = React.lazy(() => import('admin/pages/portfolios'))
const ChangePasswordPage = React.lazy(
  () => import('admin/pages/change-password')
)
export const adminRoutes: Array<IRoute> = [
  { path: '/admin', name: 'Dashboard', element: <DashboardPage /> },
  { path: '/admin/client/add', name: 'Add Client', element: <AddClientPage /> },
  {
    path: '/admin/client/view',
    name: 'View Clients',
    element: <ViewClientsPage />,
  },
  {
    path: '/admin/testimonials/add',
    name: 'Add Testimonial',
    element: <AddTestimonialPage />,
  },
  {
    path: '/admin/testimonials/view',
    name: 'View Testimonials',
    element: <ViewTestimonialPage />,
  },
  { path: '/admin/faqs/add', name: 'Add Faq', element: <AddFaqPage /> },
  {
    path: '/admin/faqs/view',
    name: 'View Faqs',
    element: <ViewFaqsPage />,
  },
  {
    path: '/admin/services/add',
    name: 'Add Service',
    element: <AddServicePage />,
  },
  {
    path: '/admin/services/view',
    name: 'View Services',
    element: <ViewServicePage />,
  },
  {
    path: '/admin/portfolios/add',
    name: 'Add Portfolio',
    element: <AddPortfolioPage />,
  },
  {
    path: '/admin/portfolios/view',
    name: 'View Portfolio',
    element: <ViewPortfolioPage />,
  },
  {
    path: '/admin/change-password',
    name: 'Change Password',
    element: <ChangePasswordPage />,
  },
  {
    path: '/admin/events/view',
    name: 'View Events',
    element: <ViewEventPage />,
  },
  {
    path: '/admin/events/add',
    name: 'Add Event',
    element: <AddEventPage />,
  },
]
