import React from 'react'

const News = () => {
  return (
    <section className="news-two">
      <div className="auto-container">
        <div className="sec-title centered">
          <div className="sec-title_icon">
            <img src="assets/images/icons/logo-icon.png" alt="" />
          </div>
          <div className="sec-title_title">News & Updates</div>
          <h2 className="sec-title_heading">Recent Articles</h2>
        </div>

        <div className="swiper mySwiper2_content swiper-container">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="news-block_three">
                <div className="news-block_three-inner">
                  <div className="news-block_three-image">
                    <img src="assets/images/resource/news-7.jpg" alt="" />
                  </div>
                  <div className="news-block_three-content">
                    <div className="news-block_three-number">01.</div>
                    <div className="news-block_three-title">Innovation</div>
                    <h5 className="news-block_three-heading">
                      <a href="#">World’s Largest Passive House Buildings</a>
                    </h5>
                    <ul className="news-block_three-meta">
                      <li>
                        <span className="icon fa-solid fa-calendar-days fa-fw"></span>
                        December 24, 2024
                      </li>
                      <li>
                        <span className="icon fa-regular fa-comment fa-fw"></span>
                        14
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="news-block_three">
                <div className="news-block_three-inner">
                  <div className="news-block_three-image">
                    <img src="assets/images/resource/news-7.jpg" alt="" />
                  </div>
                  <div className="news-block_three-content">
                    <div className="news-block_three-number">02.</div>
                    <div className="news-block_three-title">Innovation</div>
                    <h5 className="news-block_three-heading">
                      <a href="#">World’s Largest Passive House Buildings</a>
                    </h5>
                    <ul className="news-block_three-meta">
                      <li>
                        <span className="icon fa-solid fa-calendar-days fa-fw"></span>
                        December 24, 2024
                      </li>
                      <li>
                        <span className="icon fa-regular fa-comment fa-fw"></span>
                        14
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="news-block_three">
                <div className="news-block_three-inner">
                  <div className="news-block_three-image">
                    <img src="assets/images/resource/news-7.jpg" alt="" />
                  </div>
                  <div className="news-block_three-content">
                    <div className="news-block_three-number">03.</div>
                    <div className="news-block_three-title">Innovation</div>
                    <h5 className="news-block_three-heading">
                      <a href="#">World’s Largest Passive House Buildings</a>
                    </h5>
                    <ul className="news-block_three-meta">
                      <li>
                        <span className="icon fa-solid fa-calendar-days fa-fw"></span>
                        December 24, 2024
                      </li>
                      <li>
                        <span className="icon fa-regular fa-comment fa-fw"></span>
                        14
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="news-block_three">
                <div className="news-block_three-inner">
                  <div className="news-block_three-image">
                    <img src="assets/images/resource/news-7.jpg" alt="" />
                  </div>
                  <div className="news-block_three-content">
                    <div className="news-block_three-number">04.</div>
                    <div className="news-block_three-title">Innovation</div>
                    <h5 className="news-block_three-heading">
                      <a href="#">World’s Largest Passive House Buildings</a>
                    </h5>
                    <ul className="news-block_three-meta">
                      <li>
                        <span className="icon fa-solid fa-calendar-days fa-fw"></span>
                        December 24, 2024
                      </li>
                      <li>
                        <span className="icon fa-regular fa-comment fa-fw"></span>
                        14
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="news-block_three">
                <div className="news-block_three-inner">
                  <div className="news-block_three-image">
                    <img src="assets/images/resource/news-7.jpg" alt="" />
                  </div>
                  <div className="news-block_three-content">
                    <div className="news-block_three-number">05.</div>
                    <div className="news-block_three-title">Innovation</div>
                    <h5 className="news-block_three-heading">
                      <a href="#">World’s Largest Passive House Buildings</a>
                    </h5>
                    <ul className="news-block_three-meta">
                      <li>
                        <span className="icon fa-solid fa-calendar-days fa-fw"></span>
                        December 24, 2024
                      </li>
                      <li>
                        <span className="icon fa-regular fa-comment fa-fw"></span>
                        14
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="news-block_three">
                <div className="news-block_three-inner">
                  <div className="news-block_three-image">
                    <img src="assets/images/resource/news-7.jpg" alt="" />
                  </div>
                  <div className="news-block_three-content">
                    <div className="news-block_three-number">06.</div>
                    <div className="news-block_three-title">Innovation</div>
                    <h5 className="news-block_three-heading">
                      <a href="#">World’s Largest Passive House Buildings</a>
                    </h5>
                    <ul className="news-block_three-meta">
                      <li>
                        <span className="icon fa-solid fa-calendar-days fa-fw"></span>
                        December 24, 2024
                      </li>
                      <li>
                        <span className="icon fa-regular fa-comment fa-fw"></span>
                        14
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="swiper news-two_thumbs-outer mySwiper_thumbs">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <img src="assets/images/resource/news-thumb-1.jpg" alt="" />
              </div>
              <div className="swiper-slide">
                <img src="assets/images/resource/news-thumb-2.jpg" alt="" />
              </div>
              <div className="swiper-slide">
                <img src="assets/images/resource/news-thumb-3.jpg" alt="" />
              </div>
              <div className="swiper-slide">
                <img src="assets/images/resource/news-thumb-1.jpg" alt="" />
              </div>
              <div className="swiper-slide">
                <img src="assets/images/resource/news-thumb-2.jpg" alt="" />
              </div>
              <div className="swiper-slide">
                <img src="assets/images/resource/news-thumb-3.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default News
