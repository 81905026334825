import { CCol } from '@coreui/react'
import Modal from 'admin/components/modal'
import { IProject } from 'interface/IProjects'
import React, { useState } from 'react'
import { getDateTime, isVideo } from 'utils/utils'
import Image from 'components/image'
import ReactPlayer from 'react-player'

interface IProjectDetails {
  constType: string | undefined
  projectDtls: IProject
}
const ProjectDetails: React.FC<IProjectDetails> = ({
  constType,
  projectDtls,
}) => {
  const [showGallery, setShowGallery] = useState(false)
  const [selectedGallery, setSelectedGallery] = useState<
    Array<React.ReactElement>
  >([])

  const handleView = () => {
    const gallery = []
    const { image, galleryImg1, galleryImg2, galleryImg3, galleryImg4 } =
      projectDtls

    image &&
      gallery.push(
        <CCol lg={6} xs={6} md={6} sm={12} className="mb-4">
          <Image src={image} />
        </CCol>
      )
    if (galleryImg1) {
      if (isVideo(galleryImg1)) {
        gallery.push(
          <CCol lg={6} xs={6} md={6} sm={12} className="mb-4">
            <ReactPlayer
              url={galleryImg1}
              controls={true}
              width="100%"
              height="100%"
            />
          </CCol>
        )
      } else {
        gallery.push(
          <CCol lg={6} xs={6} md={6} sm={12} className="mb-4">
            <Image src={galleryImg1} />
          </CCol>
        )
      }
    }

    galleryImg2 &&
      gallery.push(
        <CCol lg={6} xs={6} md={6} sm={12} className="mb-4">
          <Image src={galleryImg2} />
        </CCol>
      )
    galleryImg3 &&
      gallery.push(
        <CCol lg={6} xs={6} md={6} sm={12} className="mb-4">
          <Image src={galleryImg3} />
        </CCol>
      )
    galleryImg4 &&
      gallery.push(
        <CCol lg={6} xs={6} md={6} sm={12} className="mb-4">
          <Image src={galleryImg4} />
        </CCol>
      )
    setSelectedGallery(gallery)
    setShowGallery(true)
  }
  return (
    <>
      <section className="project-detail">
        <div className="auto-container">
          <div className="upper-box">
            <div className="project-detail_title">{constType} Project</div>
            <h3 className="project-detail_heading">{projectDtls?.client}</h3>
            <p>{projectDtls?.description}</p>
            <ul className="news-block_three-meta">
              <li>
                <span className="icon fa-solid fa-user fa-fw"></span>
                {projectDtls?.client}
              </li>
              <li>
                <span className="icon fa-solid fa-calendar-days fa-fw"></span>
                {getDateTime(projectDtls?.completedDate)}
              </li>
              <li>
                <span className="icon fa-solid fa-location fa-fw"></span>
                {projectDtls?.location}
              </li>
              <li>
                <span className="icon fa-solid fa-eye fa-fw"></span>
                <a
                  href="#"
                  className="lnk_gallery"
                  onClick={(e) => {
                    e.preventDefault()
                    handleView()
                  }}
                >
                  Photos
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="project-detail_image">
            <img
              src={projectDtls?.image}
              alt=""
            />
            <div className="content">
              <ul>
                <li>
                  <span>CLIENT:</span>
                  {projectDtls?.client}
                </li>
                <li>
                  <span>Year:</span>2024
                </li>
                <li>
                  <span>LOCATION:</span>
                  {projectDtls?.location}
                </li>
              </ul>
            </div>
          </div> */}

          {/* <div className="project-detail_gallery">
            <div className="row clearfix">
              <div className="project-detail_gallery-image skewElem col-lg-12 col-md-12 col-sm-12">
                <img
                  src={projectDtls?.galleryImg1}
                  alt=""
                />
              </div>
              <div className="project-detail_gallery-image skewElem col-lg-6 col-md-6 col-sm-6">
                <img
                  src={projectDtls?.galleryImg2}
                  alt=""
                />
              </div>
              <div className="project-detail_gallery-image skewElem col-lg-6 col-md-6 col-sm-6">
                <img
                  src={projectDtls?.galleryImg3}
                  alt=""
                />
              </div>
              <div className="project-detail_gallery-image skewElem col-lg-12 col-md-12 col-sm-12">
                <img
                  src={projectDtls?.galleryImg4}
                  alt=""
                />
              </div>
            </div>
          </div> */}
        </div>
        <Modal
          title="Gallery"
          visible={showGallery}
          onClose={() => {
            setSelectedGallery([])
            setShowGallery(false)
          }}
          body={selectedGallery}
        />
      </section>
    </>
  )
}

export default ProjectDetails
