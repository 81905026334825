import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  CCloseButton,
  CSidebar,
  CSidebarBrand,
  CSidebarHeader,
} from '@coreui/react'

// sidebar nav config
import { RootState } from 'store'
import { IAdminStore } from 'interface/IAdmin'
import { changeState } from 'store/reducers/adminSlice'
import SidebarNav from '../sidebar-nav'
import { Link } from 'react-router-dom'
const Sidebar = () => {
  const dispatch = useDispatch()
  const adminStore = useSelector<RootState>((state) => state.admin)
  const { sidebarShow } = adminStore as IAdminStore

  return (
    <CSidebar
      className="border-end"
      colorScheme="dark"
      position="fixed"
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarHeader className="border-bottom">
        <CSidebarBrand as={Link} to="/admin">
          <img src="assets/images/SDD-LOGO-low.png" alt="" title="" />
        </CSidebarBrand>
        <CCloseButton
          className="d-lg-none"
          dark
          onClick={() => dispatch(changeState({ sidebarShow: false }))}
        />
      </CSidebarHeader>
      <SidebarNav />
    </CSidebar>
  )
}

export default React.memo(Sidebar)
